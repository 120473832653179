import * as React from 'react'
import { css, cx } from 'emotion'
import { useThemeContext } from '../contexts/ThemeContext'
import { BaseButtonProps, BaseButton } from './BaseButton'

interface Props extends BaseButtonProps {}

export function PrimaryButton(props: Props) {
  const theme = useThemeContext()

  const primaryButtonStyle = css`
    background-color: ${theme.palette.primary.regular};
    color: ${theme.palette.primary.contrastText};
    &:active:enabled,
    &:focus:enabled {
      box-shadow: ${theme.shadow?.boxShadowPrimary};
    }
    > svg {
      fill: ${theme.palette.secondary.regular};
    }
  `
  return (
    <BaseButton {...props} className={cx(primaryButtonStyle, props.className)}>
      {props.children}
    </BaseButton>
  )
}
