import { useThemeContext } from '@ikea-components'
import * as React from 'react'

interface VerticalSpacingProps {
  amount?: number
}

export function VerticalSpacing(props: VerticalSpacingProps) {
  const theme = useThemeContext()
  return (
    <div
      style={{
        flex: '0 0 !important',
        height: props.amount
          ? `${props.amount * theme.spacing.sm}rem`
          : `${theme.spacing.sm}rem`
      }}
    />
  )
}
