import { useThemeContext } from '@ikea-components'
import { css } from 'emotion'
import * as React from 'react'
import { transitions } from 'src/apps/main/helpers/CssProperties'
import { MaterialIcon } from '../Base/MaterialIcon'

interface Props {
  isSelected: boolean
  squareBackground?: boolean
  roundBackground?: boolean
  hover?: boolean // hover state can be set by hovering the label of the radio button
  color?: string
  borderColor?: string
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
}

export function CheckBox(props: Props) {
  const theme = useThemeContext()

  return (
    <div
      role="none"
      className={css({
        position: 'relative',
        width: `${theme.spacing.sm * 3}rem`,
        height: `${theme.spacing.sm * 3}rem`,
        margin: `${theme.spacing.xs}rem`,
        textAlign: 'center',
        cursor: !props.disabled ? 'pointer' : 'not-allowed',
        borderRadius: props.roundBackground ? '50%' : '0%',
        transition: transitions('background-color', 'backgroundChange'),
        backgroundColor:
          props.squareBackground || props.roundBackground
            ? props.hover
              ? theme.palette.grey[400]
              : theme.palette.grey[200]
            : 'transparent',
        '&:hover': {
          backgroundColor: props.disabled
            ? theme.palette.grey[200]
            : theme.palette.grey[400]
        }
      })}
      onClick={props.onClick}
    >
      {props.isSelected && (
        <div
          style={{
            position: 'absolute',
            top: `${theme.spacing.xs}rem`,
            left: `${theme.spacing.xs}rem`
          }}
        >
          <MaterialIcon size={`${theme.typography.icon.md}`} icon="MdCheck" />
        </div>
      )}
    </div>
  )
}
