import * as React from 'react'
import { css, cx } from 'emotion'
import { BaseButton, BaseButtonProps } from './BaseButton'
import { useThemeContext } from '../contexts'

interface Props extends BaseButtonProps {}

export function AddNewCardButton(props: Props) {
  const theme = useThemeContext()

  const cardButtonStyle = css`
    background-color: ${theme.palette.primary.regular};
    color: ${theme.palette.common.white};
    flex-direction: column;
    > svg {
      width: ${theme.spacing.sm * 8}rem;
      height: ${theme.spacing.sm * 8}rem;
      fill: ${theme.palette.secondary.regular};
    }
  `
  return (
    <BaseButton
      {...props}
      mdIcon="MdAdd"
      className={cx([cardButtonStyle, props.className])}
    />
  )
}
