import { css, cx } from 'emotion'
import * as React from 'react'
import { useThemeContext } from '../contexts'
import { BaseInput, BaseInputProps } from './BaseInput'

export interface InputProps extends BaseInputProps {
  error?: boolean
  hideNumberSteps?: boolean
}

export function Input(props: InputProps) {
  const { error, hideNumberSteps, className, ...baseProps } = props
  const theme = useThemeContext()

  function getBorderColor(action?: 'focus') {
    if (error) return theme.palette.error.regular
    if (action === 'focus') return theme.palette.secondary.regular
    if (props.value && props.value.toString().length > 0)
      return theme.palette.success.regular
    return theme.palette.grey[500]
  }

  const inputStyle = css`
    width: 100%;
    background-color: ${theme.palette.grey[100]};
    border: none;
    box-shadow: 0px -2px 0px ${getBorderColor()} inset;
    padding: ${theme.spacing.sm}rem;
    outline: none;
    font-size: ${theme.typography.p.fontSize};
    line-height: ${theme.typography.p.lineHeight};
    transition: opacity ${theme.transitions.duration.fast}s
      ${theme.transitions.easing.easeInOut};
    &:disabled {
      background-color: ${theme.palette.action.disabled.background};
      cursor: initial;
    }
    &:focus {
      box-shadow: 0px -2px 0px ${getBorderColor('focus')} inset;
    }
    &:hover:enabled {
      opacity: ${theme.palette.action.hover.opacity};
    }
    ::placeholder,
    ::-ms-input-placeholder {
      color: ${theme.palette.primary.light};
    }
    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      appearance: ${hideNumberSteps ? 'none' : 'auto'};
      margin: ${hideNumberSteps ? '0' : 'initial'};
    }
  `

  return <BaseInput {...baseProps} className={cx(inputStyle, className)} />
}
