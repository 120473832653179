import { css, cx } from 'emotion'
import * as React from 'react'
import { useThemeContext } from '../contexts'

interface TableHeaderProps
  extends React.HTMLAttributes<HTMLTableSectionElement> {
  children: React.ReactNode
}
export const TableHeader = (props: TableHeaderProps) => {
  const { children, ...baseProps } = props
  return <thead {...baseProps}>{children}</thead>
}

interface TableBodyProps extends React.HTMLAttributes<HTMLTableSectionElement> {
  children: React.ReactNode
}
export function TableBody(props: TableBodyProps) {
  const { children, ...baseProps } = props
  return <tbody {...baseProps}>{children}</tbody>
}

interface TableRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  children: React.ReactNode
  /** Add darker background color when hovering the row */
  isLink?: boolean
  /**
   * Strip table row based on given number. Odd numbers for grey background. Even for white.
   * This can be used to strip each row individually
   */
  striped?: number
}

export function TableRow(props: TableRowProps) {
  const theme = useThemeContext()
  const { children, isLink, striped, ...baseProps } = props
  const TableRow = css`
    background-color: ${striped && striped % 2
      ? theme.palette.grey[100]
      : theme.palette.common.white};
  `
  const tableRowLink = css`
    &:hover td {
      background-color: ${theme.palette.grey[300]};
    }
  `
  return (
    <tr
      {...baseProps}
      className={cx(TableRow, isLink && tableRowLink, props.className)}
      onClick={props.onClick}
    >
      {children}
    </tr>
  )
}
interface TableDataProps
  extends React.TdHTMLAttributes<HTMLTableDataCellElement> {
  children: React.ReactNode
}
export function TableData(props: TableDataProps) {
  const theme = useThemeContext()
  const { children, ...baseProps } = props
  const tableData = css`
    position: relative;
    padding: ${theme.spacing.sm}rem;
    vertical-align: middle;
  `
  return (
    <td {...baseProps} className={cx(tableData, props.className)}>
      {children}
    </td>
  )
}

interface TableHeadProps
  extends React.ThHTMLAttributes<HTMLTableHeaderCellElement> {
  children: React.ReactNode
  sticky?: boolean
}

export function TableHead(props: TableHeadProps) {
  const theme = useThemeContext()
  const { children, sticky, ...baseProps } = props
  const tableHead = css`
    position: ${sticky ? 'sticky' : 'relative'};
    top: 0; /* Don't forget this, required for the stickiness */
    padding: ${theme.spacing.sm}rem;
    vertical-align: middle;
    background-color: ${theme.palette.secondary.regular};
    border: none;
    z-index: 10;
    font-weight: ${theme.typography.fontWeightBold};
  `
  return (
    <th {...baseProps} className={cx(tableHead, props.className)}>
      {children}
    </th>
  )
}

interface TableHeaderRowProps
  extends React.HTMLAttributes<HTMLTableRowElement> {
  children: React.ReactNode
}
export const TableHeaderRow = (props: TableHeaderRowProps) => {
  const { children, ...baseProps } = props
  return <tr {...baseProps}>{children}</tr>
}

interface TableProps extends React.TableHTMLAttributes<HTMLTableElement> {
  striped?: boolean
  /** Make the first column sticky */
  fixedLeftCol?: boolean
  children: React.ReactNode
}

export function Table(props: TableProps) {
  const theme = useThemeContext()
  const { striped, fixedLeftCol, children, ...baseProps } = props
  const table = css`
    box-sizing: border-box;
    width: 100%;
    border-collapse: collapse;
    tbody tr:nth-of-type(even) {
      background-color: ${striped ? theme.palette.grey[100] : undefined};
    }
  `
  const tableFixedLeftCol = css`
    thead td:first-of-type {
      position: sticky;
      left: 0;
      z-index: 2;
      background-color: ${theme.palette.secondary.regular};
    }
    tbody td:first-of-type {
      position: sticky;
      left: 0;
      z-index: 2;
    }
    tbody tr:nth-of-type(even) {
      td:first-of-type {
        background-color: ${striped
          ? theme.palette.grey[100]
          : theme.palette.common.white};
      }
    }
    tbody tr:nth-of-type(odd) {
      td:first-of-type {
        background-color: ${theme.palette.common.white};
      }
    }
  `
  return (
    <table
      {...baseProps}
      className={cx(table, fixedLeftCol && tableFixedLeftCol, props.className)}
    >
      {children}
    </table>
  )
}
