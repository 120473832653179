import { css, cx } from 'emotion'
import * as React from 'react'
import { useThemeContext } from '../contexts'
import { BaseButton, BaseButtonProps } from './BaseButton'

interface Props extends Omit<BaseButtonProps, 'onChange'> {}

/** Adding custom props that are not conflicting with HTMLButtonAttributes s*/
interface CustomProps {
  accept?: string
  multiple?: boolean
  selectedFileName?: string
  onChange: (files: FileList) => void
}

interface AddNewFilesButtonProps extends Props, CustomProps {}

export function AddNewFilesButton(props: AddNewFilesButtonProps) {
  const theme = useThemeContext()

  const inputRef = React.useRef<HTMLInputElement>(null)

  const buttonStyle = css`
    background-color: ${theme.palette.primary.regular};
    color: ${theme.palette.common.white};
    > svg {
      fill: ${theme.palette.secondary.regular};
    }
  `

  return (
    <>
      <BaseButton
        // props on BaseButton can only be of interface CardButtonProps
        {...(props as Props)}
        className={cx(buttonStyle, props.className)}
        onClick={() => {
          inputRef.current?.click()
        }}
      >
        {props.children}
      </BaseButton>
      <input
        ref={inputRef}
        style={{ display: 'none ' }}
        type="file"
        accept={props.accept}
        multiple={props.multiple}
        onChange={e => {
          // esc triggers an event (only when input has been used once) with an empty list. this causes bugs with empty updates
          if (e.target.files && e.target.files!.length > 0) {
            props.onChange(e.target.files!)
          }
        }}
      />
    </>
  )
}
