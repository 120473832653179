/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Text, useThemeContext } from '@ikea-components'
import { css } from 'emotion'
import * as React from 'react'
import { useNotificationCenter } from '../../hooks/useNotificationCenter'
import { MaterialIcon } from '../Base/MaterialIcon'

interface Props {
  id?: string
  type: 'error' | 'success' | 'warning'
  header: string
  children: React.ReactNode
  timeInSeconds?: number
}

export function StandardToastNotification(props: Props) {
  const notificationCenter = useNotificationCenter()
  const theme = useThemeContext()

  // Default to success styling
  let backgroundColor = theme.palette.common.white
  let borderColor = theme.palette.success.light
  let color = theme.palette.common.white

  if (props.type === 'error') {
    borderColor = theme.palette.error.regular
    backgroundColor = theme.palette.error.light
    color = theme.palette.error.regular
  } else if (props.type === 'warning') {
    borderColor = theme.palette.warning.light
  }

  return (
    <div
      className={css`
        padding: ${theme.spacing.md}rem;
        padding-left: ${theme.spacing.sm * 0.75}rem;
        position: relative;
        background-color: ${backgroundColor};
        border-left: ${theme.spacing.xs}rem solid ${borderColor};
        color: ${color};
      `}
    >
      <div
        onClick={() => {
          notificationCenter.removeToast(props.id!)
        }}
        className={css`
          position: absolute;
          top: -${theme.spacing.sm * 1.5}rem;
          right: -${theme.spacing.sm * 1.5}rem;
          background-color: ${theme.palette.common.black};
          border-radius: 100%;
          height: ${theme.spacing.lg}rem;
          width: ${theme.spacing.lg}rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border: ${theme.shape.border}rem solid ${theme.palette.warning.light};

          :hover {
            cursor: pointer;
            background-color: ${theme.palette.primary.light};
          }
        `}
      >
        <MaterialIcon
          className={css`
            height: ${theme.spacing.md}rem;
            width: ${theme.spacing.md}rem;
            color: ${theme.palette.grey[50]};
          `}
          icon="MdClear"
        />
      </div>
      <div
        className={css`
          margin-left: ${theme.spacing.sm}rem;
        `}
      >
        <Text as="h4">{props.header}</Text>

        <Text as="p">{props.children}</Text>
      </div>

      {props.timeInSeconds && (
        <div
          className={css`
            margin-left: -${theme.spacing.sm * 0.75}rem;
            position: absolute;
            background-color: ${theme.palette.grey[400]};
            height: ${theme.spacing.sm * 0.5}rem;
            bottom: 0;
            width: 100%;
          `}
        >
          <div
            className={css`
              @keyframes progress-bar {
                0% {
                  width: 0;
                }
              }
              height: 100%;
              background-color: ${theme.palette.grey[600]};
              animation: progress-bar ${props.timeInSeconds}s linear;
              width: 100%;
            `}
          ></div>
        </div>
      )}
    </div>
  )
}
