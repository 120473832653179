import * as React from 'react'

interface FlexProps {
  alignItems?: 'center' | 'flex-end' | 'flex-start' | 'baseline'
  alignSelf?: 'center' | 'flex-end' | 'flex-start'
  flexDirection?: 'column' | 'column-reverse' | 'row' | 'row-reverse'
  flexWrap?: 'wrap' | 'wrap-reverse' | 'nowrap'
  justifyContent?:
    | 'center'
    | 'flex-end'
    | 'space-around'
    | 'space-between'
    | 'space-evenly'
  margin?: string
  fullHeight?: boolean
  fullWidth?: boolean
  flex?: string
  children: React.ReactNode
}

export const Flex = (props: FlexProps) => {
  return (
    <div
      style={{
        alignItems: props.alignItems || 'initial',
        alignSelf: props.alignSelf || 'initial',
        display: 'flex',
        flexDirection: props.flexDirection || 'initial',
        height: props.fullHeight ? '100%' : 'initial',
        justifyContent: props.justifyContent || 'initial',
        flexWrap: props.flexWrap || 'initial',
        flex: props.flex || 'initial',
        margin: props.margin && props.margin,
        width: props.fullWidth ? '100%' : 'auto'
      }}
    >
      {props.children}
    </div>
  )
}
Flex.displayName = 'Flex'
