import * as React from 'react'
import { css, cx } from 'emotion'
import { BaseButton, BaseButtonProps } from './BaseButton'
import { useThemeContext } from '../contexts'

interface Props extends BaseButtonProps {}

export function CardButton(props: Props) {
  const theme = useThemeContext()

  const cardButtonStyle = css`
    background-color: ${theme.palette.common.white};
    border-radius: 0;
    &:active:enabled,
    &:focus:enabled {
      box-shadow: none;
    }
  `
  return (
    <BaseButton {...props} className={cx(cardButtonStyle, props.className)} />
  )
}
