import * as React from 'react'
import { useThemeContext } from '@ikea-components'

interface HorizontalSpacingProps {
  amount?: number
}

export function HorizontalSpacing(props: HorizontalSpacingProps) {
  const theme = useThemeContext()
  return (
    <span
      style={{
        display: 'inline-block',
        flex: '0 0 !important',
        flexShrink: 0,
        width: props.amount
          ? `${props.amount * theme.spacing.sm}rem`
          : `${theme.spacing.sm}rem`
      }}
    />
  )
}
