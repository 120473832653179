import { useThemeContext } from '@ikea-components'
import * as React from 'react'
import { spacing } from '../../helpers/CssProperties'

interface Props {
  children: React.ReactNode
  preSetMinHeight?: boolean
}
export function FormBox(props: Props) {
  const theme = useThemeContext()

  return (
    <div
      style={{
        backgroundColor: theme.palette.common.white,
        padding: `${spacing * 2}rem`,
        height: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        minHeight: props.preSetMinHeight ? '50rem' : 'initial'
      }}
    >
      {props.children}
    </div>
  )
}

FormBox.displayName = 'FormBox'
