import * as React from 'react'
import { IconTypes, pathForIconType } from './IconsTypes'
import { spacing, remToPixels } from '../../helpers/CssProperties'
import { useThemeContext } from '@ikea-components'

interface Props {
  width?: number
  height?: number
  color?: string
  icon: IconTypes
}

export function Icon(props: Props) {
  const theme = useThemeContext()

  return (
    <svg
      width={props.width || spacing * remToPixels * 2}
      height={props.height || spacing * remToPixels * 2}
      viewBox="0 0 24 24"
      version="1.1"
      style={{
        fill: props.color! || theme.palette.primary.regular,
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        display: 'block'
      }}
    >
      <path d={pathForIconType(props.icon)} />
    </svg>
  )
}
