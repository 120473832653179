import { PrimaryButton, Text } from '@ikea-components'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { InputGroup } from 'src/apps/main/components/Form/InputGroup'
import { TextField } from 'src/apps/main/components/Form/TextField'
import { Flex } from 'src/apps/main/components/layout/Flex'
import { FormBox } from 'src/apps/main/components/layout/FormBox'
import { PageWrapper } from 'src/apps/main/components/layout/PageWrapper'
import { VerticalSpacing } from 'src/apps/main/components/layout/VerticalSpacing'
import { RadioButtonGroup } from 'src/apps/main/components/RadioButtons/RadioButtonGroup'
import { DefaultSpinner } from 'src/apps/main/components/Spinners/DefaultSpinner'
import { emailRegex } from 'src/apps/main/helpers/regex'
import { useNotificationCenter } from 'src/apps/main/hooks/useNotificationCenter'
import { createNewUserDocumentTitle } from '../../helpers/documentTitles'
import { useFetch } from '../../hooks/useFetch'

interface Props extends RouteComponentProps<{ id: string; jwt: string }> {}

const userTypeList = [
  { title: 'Temporary', key: 'temporary' },
  { title: 'Permanent', key: 'permanent' }
]

export function CreateNewUserPage(props: Props) {
  const [userName, setUserName] = React.useState('')
  const [userNameError, setUserNameError] = React.useState(false)
  const [temporaryUser, setTemporaryUser] = React.useState(true)
  const [userEmail, setUserEmail] = React.useState('')
  const [userEmailError, setUserEmailError] = React.useState(false)
  const [password, setPassword] = React.useState('')
  const notificationCenter = useNotificationCenter()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { data, loading }: { data: any; loading: boolean } = useFetch(
    `/api/project/info/${props.match.params.id}/${props.match.params.jwt}`
  )

  React.useEffect(() => {
    if (data && data.name) {
      document.title = createNewUserDocumentTitle(data.name)
    }
  }, [data])

  function handleSubmit() {
    if (userName === '') {
      setUserNameError(true)
    }
    if ((userEmail === '' || !userEmail.match(emailRegex)) && !temporaryUser) {
      setUserEmailError(true)
    }
    if (userName !== '' && (temporaryUser || userEmail !== '')) {
      createUser()
    }
  }

  async function createUser() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any = {
      name: userName,
      temporary: temporaryUser
    }
    if (!temporaryUser) {
      data.email = userEmail
      if (password !== '') {
        data.password = password
      }
    }
    const response = await fetch(
      `/api/project/createNewUser/${props.match.params.id}/${props.match.params.jwt}`,
      {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    if (response.status === 200) {
      if (response.redirected) {
        window.location.href = response.url
      }
    } else {
      console.error('Failed to create new user', response)
      notificationCenter.toastStandardError(
        'Failed to create new user',
        'Something went wrong when trying to create a new user. Please try again.'
      )
    }
  }
  if (loading) return <DefaultSpinner />

  if (!data) return null

  return (
    <PageWrapper maxWidth={600}>
      <FormBox>
        <form onSubmit={() => handleSubmit}>
          <Text as="h2">New user</Text>
          <VerticalSpacing />
          <Text as="h3">{data.name}</Text>
          <VerticalSpacing amount={2} />
          <InputGroup
            label="User name"
            icon="MdGroup"
            error={userNameError}
            errorText="Please provide a user name"
          >
            <TextField
              id="name"
              inputType="text"
              autoComplete="username"
              error={userNameError}
              value={userName}
              onChange={value => setUserName(value)}
              onFocus={() => setUserNameError(false)}
            />
          </InputGroup>
          <VerticalSpacing amount={2} />
          <InputGroup label="User type" icon="MdAccessTime">
            <RadioButtonGroup
              selected={temporaryUser ? 'temporary' : 'permanent'}
              list={userTypeList}
              onClick={selected => {
                setTemporaryUser(selected === 'temporary')
              }}
            />
          </InputGroup>
          <VerticalSpacing amount={2} />
          {!temporaryUser && (
            <>
              <InputGroup
                label="Email address"
                icon="MdEmail"
                error={userEmailError}
                errorText="Please provide a valid email"
              >
                <TextField
                  id="email"
                  inputType="email"
                  autoComplete="username"
                  error={userEmailError}
                  value={userEmail}
                  onFocus={() => setUserEmailError(false)}
                  onChange={value => setUserEmail(value)}
                />
              </InputGroup>
              <VerticalSpacing />
              <InputGroup label="Password" icon="MdLock" optional>
                <TextField
                  id="password"
                  inputType="password"
                  autoComplete="new-password"
                  value={password}
                  onChange={value => setPassword(value)}
                />
              </InputGroup>
            </>
          )}
          <VerticalSpacing amount={2} />
          <Flex justifyContent="flex-end">
            <PrimaryButton onClick={handleSubmit}>Create user</PrimaryButton>
          </Flex>
        </form>
      </FormBox>
    </PageWrapper>
  )
}
