import { css, cx } from 'emotion'
import * as React from 'react'
import { Icon } from '../base/Icon'
import { IconTypes } from '../base/IconsTypes'
import { MaterialIcon, MaterialIcons } from '../base/MaterialIcon'
import { useThemeContext } from '../contexts/ThemeContext'

export interface BaseButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** Default is button */
  type?: 'submit'
  children?: string
  mdIcon?: MaterialIcons
  svgIcon?: IconTypes
  className?: string
}

export function BaseButton(props: BaseButtonProps) {
  const theme = useThemeContext()

  if (props.mdIcon && props.svgIcon) {
    throw Error("mdIcon and svgIcon can't be used at the same time")
  }

  const buttonStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    font-size: ${theme.typography.p.fontSize};
    line-height: ${theme.typography.p.lineHeight};
    padding: ${theme.spacing.sm}rem;
    border: none;
    border-radius: ${theme.shape.borderRadius};
    outline: none;
    transition: opacity ${theme.transitions.duration.fast}s
      ${theme.transitions.easing.easeInOut};
    &:disabled {
      opacity: ${theme.palette.action.disabled.opacity};
      cursor: not-allowed;
    }
    &:hover:enabled {
      opacity: ${theme.palette.action.hover.opacity};
    }
    &:active:enabled,
    &:focus:enabled {
      box-shadow: ${theme.shadow.boxShadowPrimary};
    }
  `

  const { className, type, children, mdIcon, svgIcon, ...baseProps } = props
  return (
    <button
      {...baseProps}
      type={type || 'button'}
      className={cx(buttonStyle, className)}
    >
      {mdIcon && (
        <MaterialIcon
          icon={mdIcon}
          size={theme.typography.icon.md}
          color="currentColor"
          className={css`
            margin-right: ${children && theme.spacing.xs}rem;
          `}
        />
      )}
      {svgIcon && (
        <Icon
          icon={svgIcon}
          color="currentColor"
          className={css`
            margin-right: ${children && theme.spacing.xs}rem;
          `}
        />
      )}
      {children}
    </button>
  )
}
