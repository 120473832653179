import { Theme } from '@ikea-components'

/*
 * Theme-specific consts
 * Do not use these directly!
 * Only to use in THEME or project specific consts
 */
const COLORS = {
  primary: {
    light: 'rgb(134,134,134)',
    regular: 'rgb(34,34,34)'
  },
  secondary: {
    extraLight: 'rgb(255,248,204)',
    light: 'rgb(255,237,128)',
    regular: 'rgb(255, 219, 0)'
  },
  tertiery: {
    light: 'rgb(78, 133,186)',
    regular: 'rgb(0,91,175)'
  },
  error: {
    light: 'rgb(229,167,166)',
    regular: 'rgb(195,55,52)'
  },
  warning: {
    light: 'rgb(255,214,142)',
    regular: 'rgb(255,161,0)'
  },
  success: {
    light: 'rgb(142,206,178)',
    regular: 'rgb(0,144,80)'
  },
  grey: {
    50: 'rgb(251,251,251)',
    100: 'rgb(244,244,244)',
    200: 'rgb(235,235,235)',
    300: 'rgb(217,217,217)',
    400: 'rgb(210,210,210)',
    500: 'rgb(184,184,184)',
    600: 'rgb(148,148,148)',
    transparent: 'rgba(184,184,184, 0.3)'
  },
  common: {
    black: 'rgb(0,0,0)',
    white: 'rgb(255,255,255)',
    transparent: 'transparent',
    blackTransparent: 'rgba(34,34,34,0.8)'
  }
}

const HTML_FONT_SIZE = 12
document
  .getElementsByTagName('html')[0]
  .setAttribute('style', `font-size: ${HTML_FONT_SIZE}px`)

const fontSizes = {
  xs: `${10 / HTML_FONT_SIZE}rem`,
  sm: `${HTML_FONT_SIZE / HTML_FONT_SIZE}rem`,
  md: `${14 / HTML_FONT_SIZE}rem`,
  lg: `${16 / HTML_FONT_SIZE}rem`,
  xl: `${24 / HTML_FONT_SIZE}rem`,
  xxl: `${
    12 / HTML_FONT_SIZE
  }rem` /* should this be bigger? comes from css properties statsNumber*/
}

const lineHeight = {
  xs: `${14 / HTML_FONT_SIZE}rem`,
  sm: `${16 / HTML_FONT_SIZE}rem`,
  md: `${24 / HTML_FONT_SIZE}rem`,
  lg: `${24 / HTML_FONT_SIZE}rem`, // TODO, same as md?
  xl: `${32 / HTML_FONT_SIZE}rem`,
  xxl: `${36 / HTML_FONT_SIZE}rem`
}

const fontWeight = {
  regular: 400,
  bold: 700
}

const DEFAULT_SPACING = 8 / HTML_FONT_SIZE

const spacings = {
  xs: DEFAULT_SPACING / 2,
  sm: DEFAULT_SPACING,
  md: DEFAULT_SPACING * 2,
  lg: DEFAULT_SPACING * 4
}

/*
 * Primarily use theme provider to access theme-props
 */
export const baseTheme: Theme = {
  palette: {
    primary: {
      ...COLORS.primary,
      contrastText: COLORS.common.white
    },
    secondary: {
      ...COLORS.secondary,
      contrastText: COLORS.common.black
    },
    tertiery: {
      ...COLORS.tertiery,
      contrastText: COLORS.common.white
    },
    error: {
      ...COLORS.error,
      contrastText: COLORS.common.white
    },
    warning: {
      ...COLORS.warning,
      contrastText: COLORS.common.white
    },
    success: {
      ...COLORS.success,
      contrastText: COLORS.common.white
    },
    grey: COLORS.grey,
    common: COLORS.common,
    text: {
      primary: { light: COLORS.primary.light, regular: COLORS.primary.regular },
      secondary: {
        light: COLORS.secondary.light,
        regular: COLORS.secondary.regular
      },
      error: { light: COLORS.error.light, regular: COLORS.error.regular },
      success: { light: COLORS.success.light, regular: COLORS.success.regular }
    },
    action: {
      hover: {
        opacity: 0.75,
        background: ''
      },
      disabled: {
        opacity: 0.25,
        background: COLORS.grey[300]
      },
      active: {
        opacity: 0,
        background: ''
      },
      selected: {
        opacity: 0,
        background: ''
      },
      focus: {
        opacity: 0,
        background: ''
      }
    }
  },
  typography: {
    htmlFontSize: HTML_FONT_SIZE,
    fontWeightRegular: fontWeight.regular,
    fontWeightBold: fontWeight.bold,
    h1: {
      fontWeight: fontWeight.regular,
      fontSize: fontSizes.xl,
      lineHeight: lineHeight.xl
    },
    h2: {
      fontWeight: fontWeight.bold,
      fontSize: fontSizes.lg,
      lineHeight: lineHeight.lg
    },
    h3: {
      fontWeight: fontWeight.bold,
      fontSize: fontSizes.sm,
      lineHeight: lineHeight.xl
    },
    h4: {
      fontWeight: fontWeight.bold,
      fontSize: fontSizes.sm,
      lineHeight: lineHeight.sm
    },
    pSmall: {
      fontWeight: fontWeight.regular,
      fontSize: fontSizes.xs,
      lineHeight: lineHeight.xs
    },
    p: {
      fontWeight: fontWeight.regular,
      fontSize: fontSizes.sm,
      lineHeight: lineHeight.sm
    },
    pLarge: {
      fontWeight: fontWeight.regular,
      fontSize: fontSizes.lg,
      lineHeight: lineHeight.md
    },
    button: {
      fontWeight: fontWeight.regular,
      fontSize: fontSizes.sm,
      lineHeight: lineHeight.sm
    },
    link: {
      fontWeight: fontWeight.regular,
      fontSize: fontSizes.sm,
      lineHeight: lineHeight.sm,
      textDecoration: 'underline'
    },
    icon: {
      /**This value is in px */
      lg: 24,
      md: 16
    }
    /*Custom props as Mega & Price? How to handle?*/
  },
  spacing: {
    xs: spacings.xs,
    sm: spacings.sm,
    md: spacings.md,
    lg: spacings.lg
  },

  breakpoints: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920
  },
  zIndex: {
    layer1: 10,
    layer2: 20,
    layer3: 30,
    layer4: 40,
    layer5: 50,
    modal: 80,
    backdrop: 70,
    topBar: 65,
    sideBar: 60
  },
  transitions: {
    duration: {
      express: 0.01,
      fast: 0.125,
      regular: 0.2,
      slow: 0.5
    },
    easing: {
      easeInOut: 'ease-in-out',
      easeIn: 'ease',
      easeOut: 'ease-out',
      custom: `cubic-bezier([0.465, 0.183, 0.153, 0.946])`
    }
  },
  shape: {
    borderRadius: `${spacings.xs}rem`,
    border: {
      sm: '1px',
      md: '2px',
      lg: '3px'
    }
  },
  shadow: {
    boxShadowPrimary: `0 0 ${spacings.sm}rem ${COLORS.primary.regular}`,
    boxShadowSecondary: `0 0 ${spacings.sm}rem ${COLORS.secondary.regular}`,
    boxShadowError: `0 0 ${spacings.sm}rem ${COLORS.error.regular}`,
    boxShadowSuccess: `0 0 ${spacings.sm}rem ${COLORS.success.regular}`
  }
}
