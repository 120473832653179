import * as React from 'react'
import { Spinner } from './Spinner'

interface Props {
  noOffset?: boolean

}

export const DefaultSpinner = (props: Props) => {
  return <Spinner size='75px' center offsetTop={props.noOffset ? 0 : 200} />
}
