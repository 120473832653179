import * as React from 'react'
import { css, cx } from 'emotion'

interface Props {
  /** The gap between child elements. Gap is not added to last child or if it is only one child. Default gap is 0 */
  gap?: string
  className?: string
  children: React.ReactNode
}
/**
 * Basic layout component which places children in vertical stack with a set amount of gap between
 */
export function VerticalLayout(props: Props) {
  return (
    <div
      className={cx(
        [
          css`
            display: flex;
            flex-direction: column;
            > :not(:last-child) {
              margin-bottom: ${props.gap ? props.gap : 0};
            }
          `
        ],
        props.className
      )}
    >
      {props.children}
    </div>
  )
}
