import * as React from 'react'
import { css, cx } from 'emotion'
import { InputCheckbox } from './InputCheckbox'
import { useThemeContext } from '../../contexts'
import { Text } from '../../base'
import { BaseInputProps } from '../BaseInput'

interface Props extends Omit<BaseInputProps, 'className'> {
  /** Serves as group title */
  legend?: string
  description?: string
  error?: boolean
  /** Right aligned text showing additional error message */
  errorText?: string
  /** Default is inline */
  layout?: 'inline' | 'stacked'
  options: { key: string; title: string }[]
  selected: string[]
  className?: {
    root?: string
    legend?: string
    description?: string
  }
}

export function InputCheckboxGroup(props: Props) {
  const theme = useThemeContext()

  const {
    legend,
    description,
    error,
    errorText,
    layout,
    options,
    selected,
    className,
    ...baseProps
  } = props

  const inline = css`
    display: flex;
    flex-wrap: wrap;
    label {
      margin-right: ${theme.spacing.md}rem;
    }
  `
  const stacked = css`
    display: flex;
    flex-direction: column;
  `
  const legendStyle = css`
    font-weight: bold;
    font-size: ${theme.typography.p.fontSize};
    line-height: ${theme.typography.p.lineHeight};
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: ${theme.spacing.xs}rem;
  `
  const errorStyle = css`
    box-shadow: 0px -${theme.spacing.xs / 2}rem 0px ${theme.palette.error
        .regular} inset;
  `
  const descriptionStyle = css`
    margin-bottom: ${theme.spacing.sm}rem;
  `

  return (
    <fieldset className={className?.root}>
      {legend && (
        <legend className={cx(legendStyle, className?.legend)}>
          {legend}
          {!baseProps.required && error && (
            <Text
              as="p"
              pType="pSmall"
              className={css`
                color: ${theme.palette.text.error.regular};
              `}
            >
              {errorText ? errorText : 'Not selectable'}
            </Text>
          )}
          {baseProps.required && (
            <Text
              as="p"
              pType="pSmall"
              className={css`
                color: ${props.error
                  ? theme.palette.text.error.regular
                  : theme.palette.text.primary.regular};
              `}
            >
              {errorText && props.error ? errorText : '*Required'}
            </Text>
          )}
        </legend>
      )}

      {description && (
        <Text as="p" className={cx(descriptionStyle, className?.description)}>
          {description}
        </Text>
      )}

      <div
        className={cx({
          [inline]: layout === 'inline',
          [stacked]: layout === 'stacked',
          [errorStyle]: props.error === true
        })}
      >
        {options.map(option => (
          <InputCheckbox
            key={option.key}
            value={option.key}
            label={option.title}
            checked={selected.includes(option.key)}
            onChange={e => baseProps.onChange && baseProps.onChange(e)}
            {...baseProps}
          />
        ))}
      </div>
    </fieldset>
  )
}

InputCheckboxGroup.defaultProps = {
  layout: 'inline'
}
