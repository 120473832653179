import * as React from 'react'
import { Input, InputProps } from './Input'
import {
  MaterialIcons,
  MaterialIcon
} from 'src/apps/main/components/Base/MaterialIcon'
import { css, cx } from 'emotion'
import { useThemeContext } from '../contexts'

interface Props extends Omit<InputProps, 'className'> {
  icon: MaterialIcons
  className?: {
    root?: string
    icon?: string
    input?: string
  }
}

export function InputWithIcon(props: Props) {
  const theme = useThemeContext()

  const rootStyle = css`
    position: relative;
  `

  const iconStyle = css`
    position: absolute;
    top: ${theme.spacing.sm}rem;
    left: ${theme.spacing.sm}rem;
  `

  const inputStyle = css`
    padding-left: ${theme.spacing.lg}rem;
  `

  return (
    <div className={cx(rootStyle, props.className?.root)}>
      <Input {...props} className={cx(inputStyle, props.className?.input)} />
      <MaterialIcon
        icon={props.icon}
        className={cx(iconStyle, props.className?.icon)}
        size={theme.typography.icon.md}
        color={theme.palette.text.primary.light}
      />
    </div>
  )
}
