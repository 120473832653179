import * as React from 'react'
import { css } from 'emotion'
import {
  spacing,
  fontFamily,
  fontStyles,
  lineHeight,
  transitions
} from 'src/apps/main/helpers/CssProperties'
import { Label } from 'src/apps/main/components/Form/Label'
import { MaterialIcon, MaterialIcons } from '../Base/MaterialIcon'
import { useThemeContext } from '@ikea-components'

export interface Props {
  value: string
  disabled?: boolean
  error?: boolean
  errorText?: string
  inForm?: boolean
  autoFocus?: boolean
  icon?: MaterialIcons
  id?: string
  inputType?: string
  label?: string
  descriptionText?: string
  placeholder?: string
  width?: number
  optional?: boolean
  autoComplete?: string
  inputRef?: (inputElement: HTMLInputElement) => void
  onChange?: (value: string) => void
  onBlur?: (value: string) => void
  onFocus?: () => void
  onHandleKeyPress?: (e: any) => void // eslint-disable-line @typescript-eslint/no-explicit-any
}

const iconContainerSize = 2.2

export function TextField(props: Props) {
  const theme = useThemeContext()

  let borderColor = theme.palette.grey[400]

  if (props.error) {
    borderColor = theme.palette.error.regular
  } else if (props.disabled) {
    borderColor = theme.palette.grey[500]
  } else if (props.value.length > 0 && props.inForm) {
    borderColor = theme.palette.success.light
  } else if (props.value.length > 0 && !props.inForm) {
    borderColor = theme.palette.secondary.regular
  }

  return (
    <>
      {props.label && (
        <Label
          errorText={props.errorText}
          label={props.label}
          descriptionText={props.descriptionText}
          error={props.error}
          optional={props.optional}
        />
      )}
      <div
        style={{
          display: 'inline-block',
          position: 'relative',
          width: props.width || '100%'
        }}
      >
        <input
          id={props.id}
          disabled={props.disabled}
          autoComplete={props.autoComplete || 'on'}
          placeholder={props.placeholder}
          type={props.inputType || 'text'}
          value={props.value}
          autoFocus={props.autoFocus}
          className={css({
            background: props.disabled
              ? theme.palette.grey[300]
              : theme.palette.grey[100],
            border: 'none',
            boxShadow: `0px -3px 0px ${borderColor} inset`,
            // borderBottom: `3px solid ${borderColor}`,
            boxSizing: 'border-box',
            fontSize: fontStyles.p.fontSize,
            fontWeight: fontStyles.p.fontWeight,
            lineHeight: lineHeight.medium,
            transition: transitions('all', 'backgroundChange'),
            cursor: props.disabled ? 'not-allowed' : 'initial',
            fontFamily: fontFamily,
            padding: props.icon
              ? `${spacing}rem 0 ${spacing}rem ${
                  spacing + iconContainerSize
                }rem `
              : `${spacing}rem 0 ${spacing}rem ${spacing}rem`,
            width: '100%',
            '::placeholder': {
              color: theme.palette.primary.light
            },
            '::-ms-input-placeholder': {
              color: theme.palette.primary.light
            },
            '&:focus': {
              background: theme.palette.grey[100],
              boxShadow: `0px -3px 0px ${
                props.error
                  ? theme.palette.error.regular
                  : theme.palette.secondary.regular
              } inset`,
              outline: 'none'
            },
            '&:hover:not(:focus)': {
              background: props.disabled
                ? theme.palette.grey[400]
                : theme.palette.grey[200]
            }
          })}
          onKeyPress={props.onHandleKeyPress}
          onChange={e => {
            props.onChange!(e.target.value)
          }}
          onBlur={e => {
            props.onBlur && props.onBlur(e.target.value)
          }}
          onFocus={() => {
            props.onFocus && props.onFocus()
          }}
        />

        {props.icon && (
          <div
            style={{
              position: 'absolute',
              left: `${spacing}rem`,
              top: `${spacing}rem`
            }}
          >
            <MaterialIcon
              size={`${spacing * 2}rem`}
              icon={props.icon}
              color={theme.palette.primary.regular}
            />
          </div>
        )}
      </div>
    </>
  )
}
