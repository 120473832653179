import * as React from 'react'
import { fontSizes, lineHeight } from 'src/apps/main/helpers/CssProperties'
import { css } from 'emotion'
import { useThemeContext } from '@ikea-components'

interface Props {
  color?: string
  fontSize?: 'small'
  disabled?: boolean
  navLink?: boolean
  children: React.ReactNode
}

export function LinkText(props: Props) {
  const theme = useThemeContext()

  return (
    <span
      className={css`
        display: inherit;
        font-size: ${fontSizes[props.fontSize || 'medium']};
        font-weight: ${props.navLink ? 400 : 700};
        line-height: ${lineHeight[props.fontSize!]};
        color: ${props.disabled
          ? theme.palette.grey[600]
          : props.color || theme.palette.primary.regular};
        pointer-events: ${props.disabled && 'none'};
        &:hover {
          cursor: ${props.disabled ? 'default' : 'pointer'};
          text-decoration: ${props.navLink || props.disabled
            ? 'none'
            : 'underline'};
        }
      `}
    >
      {props.children}
    </span>
  )
}
