import * as React from 'react'

export interface Theme {
  palette: {
    primary: {
      light: string
      regular: string
      dark?: string
      contrastText?: string
    }
    secondary: {
      extraLight?: string
      light: string
      regular: string
      dark?: string
      contrastText?: string
    }
    tertiery: {
      light: string
      regular: string
      dark?: string
      contrastText?: string
    }
    error: {
      light: string
      regular: string
      dark?: string
      contrastText?: string
    }
    warning: {
      light: string
      regular: string
      dark?: string
      contrastText?: string
    }
    success: {
      light: string
      regular: string
      dark?: string
      contrastText?: string
    }
    info?: {
      light?: string
      regular: string
      dark?: string
      contrastText?: string
    }
    grey: {
      50: string
      100: string
      200: string
      300: string
      400: string
      500: string
      600?: string
      transparent: string
    }
    common: {
      black: string
      white: string
      transparent?: string
      blackTransparent: string
    }
    text: {
      primary: {
        light: string
        regular: string
        dark?: string
      }
      secondary: {
        light: string
        regular: string
        dark?: string
      }
      tertiery?: {
        light: string
        regular: string
        dark?: string
      }
      error: {
        light: string
        regular: string
        dark?: string
      }
      warning?: {
        light: string
        regular: string
        dark?: string
      }
      success: {
        light: string
        regular: string
        dark?: string
      }
      info?: {
        light: string
        regular: string
        dark?: string
      }
    }
    action: {
      active: {
        opacity: number
        background: string
      }
      hover: {
        opacity: number
        background: string
      }
      disabled: {
        opacity: number
        background: string
      }
      selected: {
        opacity: number
        background: string
      }
      focus: {
        opacity: number
        background: string
      }
    }
  }
  typography: {
    htmlFontSize: number
    fontWeightLight?: number
    fontWeightRegular: number
    fontWeightMedium?: number
    fontWeightBold?: number
    h1: {
      fontFamily?: string
      fontWeight: number
      fontSize: string
      lineHeight?: string
      letterSpacing?: string
    }
    h2: {
      fontFamily?: string
      fontWeight: number
      fontSize: string
      lineHeight?: string
      letterSpacing?: string
    }
    h3: {
      fontFamily?: string
      fontWeight: number
      fontSize: string
      lineHeight?: string
      letterSpacing?: string
    }
    h4?: {
      fontFamily?: string
      fontWeight: number
      fontSize: string
      lineHeight?: string
      letterSpacing?: string
    }
    h5?: {
      fontFamily?: string
      fontWeight: number
      fontSize: string
      lineHeight?: string
      letterSpacing?: string
    }
    h6?: {
      fontFamily?: string
      fontWeight: number
      fontSize: string
      lineHeight?: string
      letterSpacing?: string
    }
    pSmall?: {
      fontFamily?: string
      fontWeight: number
      fontSize: string
      lineHeight?: string
      letterSpacing?: string
    }
    p: {
      fontFamily?: string
      fontWeight: number
      fontSize: string
      lineHeight?: string
      letterSpacing?: string
    }
    pLarge?: {
      fontFamily?: string
      fontWeight: number
      fontSize: string
      lineHeight?: string
      letterSpacing?: string
    }
    button: {
      fontFamily?: string
      fontWeight: number
      fontSize: string
      lineHeight?: string
      letterSpacing?: string
      textTransform?: string
    }
    link?: {
      fontFamily?: string
      fontWeight: number
      fontSize: string
      lineHeight?: string
      letterSpacing?: string
      textTransform?: string
      textDecoration?: string
    }
    caption?: {
      fontFamily?: string
      fontWeight: number
      fontSize: string
      lineHeight?: string
      letterSpacing?: string
      textTransform?: string
    }
    icon: {
      xs?: number
      sm?: number
      md: number
      lg: number
      xl?: number
    }
  }
  spacing: {
    xs: number
    sm: number
    md: number
    lg: number
    xl?: number
  }

  breakpoints: {
    xs?: number
    sm: number
    md: number
    lg: number
    xl?: number
  }
  zIndex?: {
    layer1: number
    layer2?: number
    layer3?: number
    layer4?: number
    layer5?: number
    modal?: number
    backdrop?: number
    topBar?: number
    sideBar?: number
    tooltip?: number
  }
  transitions: {
    duration: {
      express?: number
      fast?: number
      regular: number
      slow?: number
      enter?: number
      leave?: number
    }
    easing: {
      easeInOut: string
      easeIn?: string
      easeOut?: string
      sharp?: string
      custom?: string
    }
  }
  shape: {
    borderRadius?: string
    border: {
      sm: string
      md: string
      lg: string
    }
  }
  shadow: {
    boxShadowPrimary: string
    boxShadowSecondary?: string
    boxShadowTertiery?: string
    boxShadowError?: string
    boxShadowWarning?: string
    boxShadowSuccess?: string
    boxShadowInfo?: string
  }
}

interface Props {
  theme: Theme
  children: React.ReactNode
}

const Context = React.createContext<Theme | undefined>(undefined)

export function ThemeContext(props: Props) {
  return (
    <Context.Provider value={props.theme}>{props.children}</Context.Provider>
  )
}

export function useThemeContext() {
  const context = React.useContext(Context)
  if (context === undefined) {
    throw new Error(
      'useThemeContext must be used within a ThemeContextProvider'
    )
  }
  return context
}
