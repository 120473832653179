import * as React from 'react'
import { useThemeContext } from '../contexts'
import { IconTypes, pathForIconType } from './IconsTypes'
import { css, cx } from 'emotion'

interface IconProps {
  icon: IconTypes
  className?: string
  color?: string
  height?: number
  width?: number
}

export function Icon(props: IconProps) {
  const theme = useThemeContext()
  return (
    <svg
      width={
        props.width
          ? props.width
          : theme.typography.icon
          ? theme.typography.icon.md
          : 16
      }
      height={
        props.height
          ? props.height
          : theme.typography.icon
          ? theme.typography.icon.md
          : 16
      }
      viewBox="0 0 24 24"
      version="1.1"
      className={cx(
        css`
          fill: ${props.color! || theme.palette.primary.regular};
          fill-rule: evenodd;
          clip-rule: evenodd;
          display: block;
        `,
        props.className
      )}
    >
      <path d={pathForIconType(props.icon)} />
    </svg>
  )
}
