import { Text, useThemeContext } from '@ikea-components'
import { css } from 'emotion'
import * as React from 'react'
import { HorizontalSpacing } from 'src/apps/main/components/layout/HorizontalSpacing'
import { spacing } from 'src/apps/main/helpers/CssProperties'

interface Props {
  label: string
  optional?: boolean
  error?: boolean
  errorText?: string
  descriptionText?: string
  paddingForIcon?: boolean
}

export const Label = (props: Props) => {
  const theme = useThemeContext()
  return (
    <div
      style={{
        paddingLeft: props.paddingForIcon ? `${spacing * 3}rem` : 'initial'
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
          marginBottom: `${spacing / 2}rem`
        }}
      >
        <Text
          as="p"
          className={css`
            font-weight: bold;
          `}
        >
          {props.label}
        </Text>
        {props.error && (
          <>
            <HorizontalSpacing />
            <Text
              as="p"
              pType="pSmall"
              className={css`
                color: ${theme.palette.error.regular};
                white-space: nowrap;
              `}
            >
              {props.errorText ? props.errorText : 'Not a valid input'}
            </Text>
          </>
        )}
        {!props.error && props.optional && (
          <Text as="p" pType="pSmall">
            Optional
          </Text>
        )}
      </div>
      {props.descriptionText && (
        <div
          style={{
            marginBottom: `${spacing / 2}rem`
          }}
        >
          <Text
            as="p"
            pType="pSmall"
            className={css`
              color: ${theme.palette.primary.light};
            `}
          >
            {props.descriptionText}{' '}
          </Text>
        </div>
      )}
    </div>
  )
}
