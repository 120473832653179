import { PrimaryButton, Text, useThemeContext } from '@ikea-components'
import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import logo from 'src/apps/login/assets/ikea-logo-bw.svg'
import { TextField } from 'src/apps/main/components/Form/TextField'
import { VerticalSpacing } from 'src/apps/main/components/layout/VerticalSpacing'
import 'whatwg-fetch'
import { resetDocumentTitle } from '../../helpers/documentTitles'

type Props = RouteComponentProps<{ token: string }>

export function ResetPage(props: Props) {
  document.title = resetDocumentTitle
  const theme = useThemeContext()
  const [password, setPassword] = React.useState('')

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    window
      .fetch(`/api/user/password/reset/${props.match.params.token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          password
        })
      })
      .then(res => {
        if (res.status === 200) {
          props.history.push('/')
        } else {
          console.error('error')
        }
      })
  }

  return (
    <div style={{ padding: `${theme.spacing.sm * 2.4}rem` }}>
      <div style={{ height: '10vh' }} />
      <div
        style={{
          maxWidth: `${theme.spacing.sm * 245}rem`,
          margin: '0 auto',
          padding: `${theme.spacing.sm * 2.4}rem`,
          backgroundColor: theme.palette.common.white
        }}
      >
        <VerticalSpacing amount={2} />
        {/* lineHeight comes from old image component, dunno why it was used? */}
        <div style={{ textAlign: 'center', lineHeight: 0 }}>
          <img
            alt="Ikea logo"
            src={logo}
            style={{
              width: '100px'
            }}
          />
        </div>
        <VerticalSpacing amount={8} />
        <Text as="p">Please enter a new password</Text>
        <VerticalSpacing amount={2} />
        <form onSubmit={handleSubmit}>
          <TextField
            inputType="password"
            autoFocus
            value={password}
            onChange={(value: string) => setPassword(value)}
            label="Password"
          />
          <VerticalSpacing amount={4} />
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
          >
            <PrimaryButton type="submit">Reset</PrimaryButton>
          </div>
        </form>
      </div>
    </div>
  )
}
