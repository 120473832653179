import { css, cx } from 'emotion'
import * as React from 'react'
import { Text } from '../base'
import { TextArea, TextAreaProps } from './TextArea'
import { useThemeContext } from '../contexts'

interface Props extends Omit<TextAreaProps, 'className'> {
  label: string
  description?: string
  /** Right aligned text showing additional error message */
  errorText?: string
  className?: {
    description?: string
    label?: string
    root?: string
    textarea?: string
  }
}

export function TextAreaWithLabel(props: Props) {
  const id = Math.floor(Math.random() * 1000)
  const theme = useThemeContext()

  const labelStyle = css`
    align-items: flex-start;
    display: flex;
    font-weight: bold;
    justify-content: space-between;
    margin-bottom: ${theme.spacing.xs}rem;
  `

  const descriptionStyle = css`
    margin-bottom: ${theme.spacing.sm}rem;
  `

  const { className, description, errorText, label, ...baseProps } = props
  return (
    <fieldset className={className?.root}>
      <label
        htmlFor={`${label.toLowerCase()}_${id}`}
        className={cx(labelStyle, className?.label)}
      >
        {label}
        {!props.required && props.error && (
          <Text
            as="p"
            pType="pSmall"
            className={css`
              color: ${theme.palette.text.error.regular};
            `}
          >
            {errorText ? errorText : 'Not a valid input'}
          </Text>
        )}
        {props.required && (
          <Text
            as="p"
            pType="pSmall"
            className={css`
              color: ${props.error
                ? theme.palette.text.error.regular
                : theme.palette.text.primary.regular};
            `}
          >
            {errorText && props.error ? errorText : '*Required'}
          </Text>
        )}
      </label>

      {description && (
        <Text as="p" className={cx(descriptionStyle, className?.description)}>
          {description}
        </Text>
      )}
      <TextArea
        id={`${props.label.toLowerCase()}_${id}`}
        className={className?.textarea}
        {...baseProps}
      />
    </fieldset>
  )
}
