import * as React from 'react'
import { render, unmountComponentAtNode } from 'react-dom'

import 'src/apps/login/styles/style.css'

import App from 'src/apps/login/App'

const mountPoint =
  document.getElementById('app-root') || document.createElement('div')
mountPoint.id = 'app-root'
document.body.insertBefore(mountPoint, document.body.children[0])

render(<App />, mountPoint)

if (module.hot) {
  module.hot.accept('./App', () => {
    unmountComponentAtNode(mountPoint)
    const NewApp = require('./App').default
    render(<NewApp />, mountPoint)
  })
}
