import { Text } from '@ikea-components'
import { css } from 'emotion'
import * as React from 'react'
import { Hover } from 'src/apps/main/components/Base/Hover'
import { HorizontalSpacing } from 'src/apps/main/components/layout/HorizontalSpacing'
import { spacing } from '../../helpers/CssProperties'
import { CheckBox } from '../Icon/CheckBox'

interface Props {
  id: string
  disabled?: boolean
  label?: string
  isSelected: boolean
  onClick: (selected: string) => void
}

export const RadioButton = (props: Props) => {
  return (
    <>
      <Hover>
        {isHovering => (
          <div
            className={css({
              display: 'flex',
              cursor: props.disabled ? 'not-allowed' : 'pointer'
            })}
            onClick={() => props.onClick(props.id)}
          >
            <CheckBox
              isSelected={props.isSelected}
              disabled={props.disabled}
              roundBackground
              hover={!props.disabled && isHovering}
            />
            <HorizontalSpacing amount={1} />
            <div
              className={css({
                padding: `${spacing}rem 0`
              })}
            >
              <Text as="p">{props.label}</Text>
            </div>
          </div>
        )}
      </Hover>
    </>
  )
}
