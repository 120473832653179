import * as React from 'react'

export function useFetch(url: string) {
  const [data, setData] = React.useState()
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    async function fetchUrl() {
      const response = await fetch(url)
      const json = await response.json()
      setData(json)
      setLoading(false)
    }
    fetchUrl()
  })
  return { data, loading }
}
