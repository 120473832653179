import * as React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Switch } from 'react-router'

import { LoginPage } from 'src/apps/login/pages/login/LoginPage'
import { ForgotPage } from 'src/apps/login/pages/forgot/ForgotPage'
import { ResetPage } from 'src/apps/login/pages/reset/ResetPage'
import { CreateNewUserPage } from './pages/createNewUser/CreateNewUserPage'
import { ThemeContext } from '@ikea-components'
import { baseTheme } from './themes/baseTheme'

export default class App extends React.Component<{}> {
  render() {
    return (
      <ThemeContext theme={baseTheme}>
        <Router basename="login">
          <Switch>
            <Route exact path="/" component={LoginPage} />
            <Route exact path="/forgot" component={ForgotPage} />
            <Route exact path="/forgotPassword/:token" component={ResetPage} />
            <Route
              exact
              path="/project/:id/createNewUser/:jwt"
              component={CreateNewUserPage}
            />
          </Switch>
        </Router>
      </ThemeContext>
    )
  }
}
