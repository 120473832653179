import { css } from 'emotion'
import { Colors } from './Colors'

/**
 * READ THIS, PROPERTIES IN THIS FILE SHOULD MOST LIKELY BE IN THE THEME
 * IF A CONST IS UNUSED REMOVE IT
 * IF YOU ARE ABOUT TO USE A CONST HERE, MOVE IT TO THE THEME
 * (There might be cases were we don't want all const to be in theme. Then it could be saved here)
 */

export const remToPixels = 12

export const spacing = 8 / 12

export const spacingSmall = spacing * 0.5
export const spacingSmallRem = `${spacingSmall}rem`
export const spacingSmallPx = `${spacingSmall * remToPixels}px`

export const spacingHalf = spacing
export const spacingHalfRem = `${spacingHalf}rem`
export const spacingHalfPx = `${spacingHalf * remToPixels}px`

export const spacingStandard = spacing * 2
export const spacingStandardRem = `${spacingStandard}rem`
export const spacingStandardPx = `${spacingStandard * remToPixels}px`

export const spacingDouble = spacing * 4
export const spacingDoubleRem = `${spacingDouble}rem`
export const spacingDoublePx = `${spacingDouble * remToPixels}px`

export const headerHeight = 4
export const mobileBreadCrumbHeaderHeight = 4

export const defaultIconSize = 16

export const transitionDuration = {
  express: '0.01s',
  fast: '0.125s',
  default: '0.2s',
  medium: '0.3s',
  slow: '0.5s'
}

export const transitionTiming = {
  default: 'ease-in-out',
  simple: 'ease',
  custom: 'cubic-bezier(0.465, 0.183, 0.153, 0.946)'
}

type transitionType =
  | 'hover'
  | 'backgroundChange'
  | 'horizontalIn'
  | 'horizontalOut'
  | 'verticalExpand'
  | 'verticalCollapse'
  | 'flip'
  | 'tooltip'

const transitionShorthands: { [key in transitionType]: string } = {
  hover: `${transitionDuration.default} ${transitionTiming.default}`,
  backgroundChange: `${transitionDuration.fast} ${transitionTiming.default}`,
  horizontalIn: `${transitionDuration.medium} ${transitionTiming.custom}`,
  horizontalOut: `${transitionDuration.fast} ${transitionTiming.simple}`,
  verticalExpand: `${transitionDuration.medium} ${transitionTiming.custom}`,
  verticalCollapse: `${transitionDuration.express} ${transitionTiming.simple}`,
  flip: `${transitionDuration.slow} ${transitionTiming.custom}`,
  tooltip: `${transitionDuration.default} ${transitionTiming.default}`
}

export const transitions = (
  cssProperty: string,
  transitionType: transitionType
) => {
  return `${cssProperty} ${transitionShorthands[transitionType]}`
}

export const sidebarWidth = {
  desktop: {
    collapsed: '58px',
    expanded: '250px'
  },
  handheld: {
    expanded: '100%'
  }
}

export const fontSizes = {
  small: 10 / 12 + 'rem',
  medium: 12 / 12 + 'rem',
  default: 14 / 12 + 'rem',
  large: 18 / 12 + 'rem',
  extraLarge: 21 / 12 + 'rem',
  statsNumber: '1rem'
}

export const lineHeight = {
  small: 14 / 12 + 'rem',
  medium: 16 / 12 + 'rem',
  default: 24 / 12 + 'rem',
  large: 24 / 12 + 'rem',
  extraLarge: 32 / 12 + 'rem',
  statsNumber: 36 / 12 + 'rem'
}

export const fontFamily = 'NotoIKEALatin'

export const fontStyles = {
  h1: {
    fontSize: fontSizes.extraLarge,
    fontWeight: 700,
    lineHeight: lineHeight.extraLarge
  },
  h2: {
    fontSize: fontSizes.large,
    fontWeight: 700,
    lineHeight: lineHeight.large
  },
  h3: {
    fontSize: fontSizes.default,
    fontWeight: 700,
    lineHeight: lineHeight.default
  },
  h4: {
    fontSize: fontSizes.medium,
    fontWeight: 700,
    lineHeight: lineHeight.medium
  },
  p: {
    fontSize: fontSizes.medium,
    fontWeight: 400,
    lineHeight: lineHeight.medium
  },
  pBold: {
    // Depricated not part of new TextElement
    fontSize: fontSizes.medium,
    fontWeight: 700,
    lineHeight: lineHeight.medium
  },
  pLarge: {
    fontSize: fontSizes.default,
    fontWeight: 400,
    lineHeight: lineHeight.default
  },
  pLargeBold: {
    // Depricated not part of new TextElement
    fontSize: fontSizes.default,
    fontWeight: 700,
    lineHeight: lineHeight.default
  },
  pSmall: {
    fontSize: fontSizes.small,
    fontWeight: 400,
    lineHeight: lineHeight.small
  },
  pSmallBold: {
    //  Depricated not part of new TextElement
    fontSize: fontSizes.small,
    fontWeight: 700,
    lineHeight: lineHeight.small
  },
  price: {
    fontSize: '3.2rem',
    fontWeight: 700,
    lineHeight: '1.5'
  },
  mega: {
    fontSize: fontSizes.extraLarge,
    fontWeight: 700,
    lineHeight: lineHeight.statsNumber
  }
}

export const zIndex = {
  layer1: 10,
  layer2: 20,
  layer3: 30,
  layer4: 40,
  layer5: 50,
  sideMenu: 60,
  header: 65,
  modalBackdrop: 70,
  modal: 80
}
export const inputFieldSize = '16.67rem'

export const screenSize = {
  small: '576px',
  medium: '896px',
  large: '1224px',
  xlarge: '1824px'
}

export const mdIconInteractiveClass = (disabled: boolean = false) =>
  css`
    padding: ${spacing}rem;
    &:hover {
      cursor: ${disabled ? 'not-allowed' : 'cursor'};
      background-color: ${Colors.cc_secondary_050};
    }
  `

export const IdeaSelectCheckboxClass = (selected: boolean) => css`
  transition: opacity 0.2s ease-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: ${selected ? 1 : 0};
  cursor: pointer;
`
