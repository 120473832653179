import * as React from 'react'
import { css } from 'emotion'
import { useThemeContext } from '@ikea-components'

interface Props {
  size: string
  width?: number
  offsetTop?: number
  center?: boolean
  inverted?: boolean
  color?: string
}

export function Spinner(props: Props) {
  const theme = useThemeContext()

  return (
    <div
      className={css({
        position: 'relative',
        border: `solid ${
          props.inverted ? theme.palette.grey[100] : theme.palette.common.white
        }`,
        borderTop: `solid ${props.color || theme.palette.secondary.regular}`,
        borderWidth: `${props.width ? props.width : 5}px`,
        borderRadius: '50%',
        width: `${props.size}`,
        height: `${props.size}`,
        marginTop: props.offsetTop ? props.offsetTop : 0,
        marginLeft: props.center ? 'auto' : 0,
        marginRight: props.center ? 'auto' : 0,
        animation: 'spin 2s linear infinite',
        '@keyframes spin': {
          '0%': { transform: 'rotate(0deg)' },
          '100%': { transform: 'rotate(360deg)' }
        }
      })}
    />
  )
}
