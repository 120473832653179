import { css, cx } from 'emotion'
import * as React from 'react'
import { spacing } from '../../../apps/main/helpers/CssProperties'
import { useThemeContext } from '../contexts'
import { BaseButton, BaseButtonProps } from './BaseButton'

interface Props extends Omit<BaseButtonProps, 'icon'> {}

export function TransparentButton(props: Props) {
  const theme = useThemeContext()

  const transparentButtonStyle = css`
    background-color: initial;
    text-decoration: underline;
    color: ${theme.palette.common.black};
    padding: ${spacing}rem 0;
    &:active:enabled,
    &:focus:enabled {
      box-shadow: none;
      opacity: 0.5;
    }
  `
  return (
    <BaseButton
      {...props}
      className={cx(transparentButtonStyle, props.className)}
    >
      {props.children}
    </BaseButton>
  )
}
