import { css, cx } from 'emotion'
import * as React from 'react'
import { spacing } from '../../../apps/main/helpers/CssProperties'
import { useThemeContext } from '../contexts'
import { BaseButton, BaseButtonProps } from './BaseButton'

interface Props extends BaseButtonProps {}

export function SuccessButton(props: Props) {
  const theme = useThemeContext()

  const successButtonStyle = css`
    background-color: ${theme.palette.success.regular};
    color: ${theme.palette.common.white};
    &:active:enabled,
    &:focus:enabled {
      box-shadow: 0 0 ${spacing}rem ${theme.palette.success.regular};
    }
  `
  return (
    <BaseButton {...props} className={cx(successButtonStyle, props.className)}>
      {props.children}
    </BaseButton>
  )
}


