import { css, cx } from 'emotion'
import * as React from 'react'
import { MaterialIcons } from 'src/apps/main/components/Base/MaterialIcon'
import { Text } from '../base'
import { useThemeContext } from '../contexts'
import { Input, InputProps } from './Input'
import { InputWithIcon } from './InputWithIcon'

interface Props extends Omit<InputProps, 'className'> {
  label: string
  description?: string
  /** Right aligned text showing additional error message */
  errorText?: string
  inputIcon?: MaterialIcons
  className?: {
    root?: string
    label?: string
    description?: string
    input?: string
    inputIcon?: string
  }
}

export function InputWithLabel(props: Props) {
  const id = Math.floor(Math.random() * 1000)
  const theme = useThemeContext()

  const labelStyle = css`
    font-weight: bold;
    font-size: ${theme.typography.p.fontSize};
    line-height: ${theme.typography.p.lineHeight};
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: ${theme.spacing.xs}rem;
  `
  const descriptionStyle = css`
    margin-bottom: ${theme.spacing.sm}rem;
  `
  const {
    errorText,
    label,
    description,
    className,
    inputIcon,
    ...baseProps
  } = props
  return (
    <fieldset className={className?.root}>
      <label
        htmlFor={label && `${label.toLowerCase()}_${id}`}
        className={cx(labelStyle, className?.label)}
      >
        {label}
        {!props.required && props.error && (
          <Text
            as="p"
            pType="pSmall"
            className={css`
              color: ${theme.palette.text.error.regular};
            `}
          >
            {errorText ? errorText : 'Not a valid input'}
          </Text>
        )}
        {props.required && (
          <Text
            as="p"
            pType="pSmall"
            className={css`
              color: ${props.error
                ? theme.palette.text.error.regular
                : theme.palette.text.primary.regular};
            `}
          >
            {errorText && props.error ? errorText : '*Required'}
          </Text>
        )}
      </label>

      {description && (
        <Text as="p" className={cx(descriptionStyle, className?.description)}>
          {description}
        </Text>
      )}

      {inputIcon && (
        <InputWithIcon
          id={`${label.toLowerCase()}_${id}`}
          {...baseProps}
          className={{
            icon: className?.inputIcon,
            input: className?.input
          }}
          icon={inputIcon}
        />
      )}

      {!props.inputIcon && (
        <Input
          id={`${props.label.toLowerCase()}_${id}`}
          {...baseProps}
          className={className?.input}
        />
      )}
    </fieldset>
  )
}
