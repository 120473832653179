import * as React from 'react'
import { css, cx } from 'emotion'
import { useThemeContext } from '../../contexts'
import { transitions } from 'src/apps/main/helpers/CssProperties'
import { BaseInput } from '../BaseInput'

export interface CheckRadioBaseProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
}

export function CheckRadioBase(props: CheckRadioBaseProps) {
  const theme = useThemeContext()

  const { label } = props

  const labelStyle = css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    user-select: none;
  `
  const customInput = css`
    position: relative;
    width: ${theme.spacing.sm * 1.5}rem;
    height: ${theme.spacing.sm * 1.5}rem;
    border: ${theme.spacing.sm * 0.25}rem solid ${theme.palette.common.black};
    margin: ${theme.spacing.sm * 1.25}rem ${theme.spacing.sm}rem
      ${theme.spacing.sm * 1.25}rem 0;

    &:after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: scale(0);
      background-color: ${theme.palette.common.white};
      transition: ${transitions('all', 'hover')};
      transform-origin: center;
    }
  `

  const input = css`
    /* Haxx to keep element visible for screen readers  */
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    /* Haxx to keep element visible for screen readers  */
    &:checked + span:after {
      background-color: ${theme.palette.common.black};
      transform: scale(1);
    }
  `
  return (
    <>
      <label
        className={cx([
          labelStyle,
          css`
            cursor: ${!props.disabled ? 'pointer' : 'not-allowed'};
          `
        ])}
      >
        <BaseInput className={cx(input)} {...props} />
        <span
          className={cx([
            customInput,
            css`
              border-radius: ${props.type === 'radio' && '50%'};
              &:after {
                border-radius: ${props.type === 'radio' && '50%'};
              }
              margin-right: ${label && theme.spacing.xs}rem;
            `
          ])}
        />
        {label}
      </label>
    </>
  )
}
