import { css, cx } from 'emotion'
import * as React from 'react'
import { useThemeContext } from '../contexts/ThemeContext'

interface Props {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'p'
  pType?: 'pLarge' | 'pSmall'
  className?: string
  /** Truncate text (ex tex..) if it is wider then the container. Only works with display: block */
  truncate?: boolean
  children: React.ReactNode
}

// TODO, letter spacing
export function Text(props: Props) {
  const theme = useThemeContext()

  if (props.as !== 'p' && props.pType)
    throw Error('You can only use props.pType with as === "p"')

  if (props.pType === 'pSmall' && theme.typography.pSmall === undefined) {
    throw Error('pSmall is not defined for this theme!')
  }

  if (props.pType === 'pLarge' && theme.typography.pLarge === undefined) {
    throw Error('pLarge is not defined for this theme!')
  }

  if (props.as === 'h4' && theme.typography.h4 === undefined) {
    throw Error('h4 is not defined for this theme!')
  }

  const Element = props.as ? props.as : 'span'

  const textStyles = css`
    color: ${theme.palette.text.primary.regular};
    font-size: ${!props.as && theme.typography.p.fontSize};
    white-space: ${props.truncate && 'nowrap'};
    overflow: ${props.truncate && 'hidden'};
    text-overflow: ${props.truncate && 'ellipsis'};
    word-wrap: break-word;
  `
  const pStyle = css`
    font-size: ${theme.typography.p.fontSize};
    line-height: ${theme.typography.p.lineHeight};
  `
  const pSmall = css`
    font-size: ${theme.typography.pSmall?.fontSize};
    letter-spacing: 0.02rem;
    font-weight: ${theme.typography.pSmall?.fontWeight};
    line-height: ${theme.typography.pSmall?.lineHeight};
  `
  const pLarge = css`
    font-size: ${theme.typography.pLarge?.fontSize};
    font-weight: ${theme.typography.pLarge?.fontWeight};
    line-height: ${theme.typography.pLarge?.lineHeight};
  `
  const H1Style = css`
    font-size: ${theme.typography.h1.fontSize};
    padding-bottom: ${theme.spacing.md}rem;
    font-weight: ${theme.typography.h1.fontWeight};
    line-height: ${theme.typography.h1.lineHeight};
  `
  const H2Style = css`
    font-size: ${theme.typography.h2.fontSize};
    padding-bottom: ${theme.spacing.sm}rem;
    font-weight: ${theme.typography.h2.fontWeight};
    line-height: ${theme.typography.h2.lineHeight};
  `
  const H3Style = css`
    font-size: ${theme.typography.h3.fontSize};
    text-transform: uppercase !important;
    font-weight: ${theme.typography.h3.fontWeight};
    line-height: ${theme.typography.h3.lineHeight};
  `
  const H4Style = css`
    font-size: ${theme.typography.h4?.fontSize};
    font-weight: ${theme.typography.h4?.fontWeight};
    line-height: ${theme.typography.h4?.lineHeight};
  `
  const SpanTextStyle = css`
    font-size: ${theme.typography.p.fontSize};
    font-weight: ${theme.typography.p.fontWeight};
    line-height: ${theme.typography.p.lineHeight};
  `
  return (
    <Element
      className={cx(
        props.as === 'h1'
          ? H1Style
          : props.as === 'h2'
          ? H2Style
          : props.as === 'h3'
          ? H3Style
          : props.as === 'h4'
          ? H4Style
          : props.as === 'p'
          ? pStyle
          : SpanTextStyle,
        props.pType === 'pSmall'
          ? pSmall
          : props.pType === 'pLarge'
          ? pLarge
          : undefined,
        textStyles,
        props.className
      )}
    >
      {props.children}
    </Element>
  )
}
