import * as React from 'react'
import { remToPixels, spacing } from '../../helpers/CssProperties'
import { MaterialIcon, MaterialIcons } from '../Base/MaterialIcon'
import { Icon } from '../Icon/Icon'
import { IconTypes } from '../Icon/IconsTypes'
import { Flex } from '../layout/Flex'
import { Label } from './Label'

interface Props {
  id?: string
  label?: string
  descriptionText?: string
  optional?: boolean
  error?: boolean
  icon?: MaterialIcons
  svgIcon?: IconTypes
  paddingForNoIcon?: boolean // use this if the input group should be aligned like a box with icon (but you dont want to have an icon)
  iconSize?: number
  errorText?: string
  children?: React.ReactNode
}

export const InputGroup = (props: Props) => {
  if (props.icon && props.svgIcon) {
    throw new Error('Use either icon or svgIcon, not both')
  }
  return (
    <div
      id={props.id}
      style={{
        width: '100%'
      }}
    >
      <div
        style={{
          paddingLeft:
            props.icon || props.paddingForNoIcon || props.svgIcon
              ? `${spacing * 4}rem`
              : 'initial'
        }}
      >
        <Label
          label={props.label || ''}
          error={props.error}
          errorText={props.errorText}
          optional={props.optional}
          descriptionText={props.descriptionText}
        />
      </div>
      <Flex>
        {props.icon && (
          <div
            style={{
              width: `${spacing * 4}rem`,
              paddingTop: `${spacing * 0.75}rem`,
              flexShrink: 0
            }}
          >
            <MaterialIcon
              icon={props.icon ? props.icon : 'MdSubject'}
              size={spacing * remToPixels * 2.5}
            />
          </div>
        )}
        {props.svgIcon && (
          <div
            style={{
              width: `${spacing * 4}rem`,
              paddingTop: `${spacing * 0.75}rem`,
              flexShrink: 0
            }}
          >
            <Icon
              icon={props.svgIcon}
              width={spacing * remToPixels * 2.5}
              height={spacing * remToPixels * 2.5}
            />
          </div>
        )}
        <div
          style={{
            paddingLeft: props.paddingForNoIcon
              ? `${spacing * 4}rem`
              : 'initial',
            width: '100%'
          }}
        >
          {props.children}
        </div>
      </Flex>
    </div>
  )
}

InputGroup.displayName = 'InputGroup'
