import * as React from 'react'
import { Label } from 'src/apps/main/components/Form/Label'
import { RadioButton } from 'src/apps/main/components/RadioButtons/RadioButton'
import { spacing } from '../../helpers/CssProperties'

interface Props {
  list: { key: string; title: string }[]
  label?: string
  disabled?: boolean
  horizontalItems?: boolean
  error?: boolean
  errorText?: string
  optional?: boolean
  selected?: string
  onClick: (selected: string) => void
}

export function RadioButtonGroup(props: Props) {
  return (
    <div>
      {props.label && (
        <Label
          label={props.label}
          error={props.error}
          optional={props.optional}
          errorText={props.errorText}
        />
      )}
      <ul>
        {props.list.map(listItem => (
          <li
            key={listItem.key}
            style={{
              display: props.horizontalItems ? 'inline-block' : 'list-item',
              margin: `${spacing * 0.5}rem 0`
            }}
          >
            <RadioButton
              id={listItem.key}
              disabled={props.disabled}
              label={listItem.title}
              isSelected={listItem.key === props.selected}
              onClick={props.onClick}
            />
          </li>
        ))}
      </ul>
    </div>
  )
}
