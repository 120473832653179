import * as React from 'react'
import * as MdIcon from 'react-icons/md/index'
import { IconBaseProps } from 'react-icons/lib/iconBase'

export type MaterialIcons =
  | 'MdPersonAdd'
  | 'MdGroup'
  | 'MdCancel'
  | 'MdEmail'
  | 'MdAdd'
  | 'MdArrowForward'
  | 'MdCheck'
  | string

interface Props extends IconBaseProps {
  icon: MaterialIcons
}

export function MaterialIcon(props: Props) {
  //@ts-ignore
  const Icon = MdIcon[props.icon]

  return <Icon display="block" {...props} />
}
