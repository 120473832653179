import { css, cx } from 'emotion'
import * as React from 'react'
import { Text } from '../base'
import { useThemeContext } from '../contexts'

interface Props {
  title: string
  description?: string
  error?: boolean
  /** Right aligned text showing additional error message */
  errorText?: string
  /** default is optional */
  required?: boolean
  className?: {
    root?: string
    title?: string
    description?: string
    error?: string
  }
  children: React.ReactNode
}

export function GroupWithTitle(props: Props) {
  const theme = useThemeContext()

  const titleStyle = css`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    font-weight: bold;
  `

  return (
    <fieldset className={props.className?.root}>
      <legend className={cx(titleStyle, props.className?.title)}>
        {props.title}
        {!props.required && props.error && (
          <Text
            as="p"
            pType="pSmall"
            className={cx(
              css`
                color: ${theme.palette.text.error.regular};
              `,
              props.className?.error
            )}
          >
            {props.errorText ? props.errorText : 'Not a valid input'}
          </Text>
        )}
        {props.required && (
          <Text
            as="p"
            pType="pSmall"
            className={cx(
              css`
                color: ${props.error
                  ? theme.palette.text.error.regular
                  : theme.palette.text.primary.regular};
              `,
              props.className?.error
            )}
          >
            {props.errorText && props.error ? props.errorText : '*Required'}
          </Text>
        )}
      </legend>

      {props.description && (
        <Text
          as="p"
          className={cx(
            css`
              margin-bottom: ${theme.spacing.sm}rem;
            `,
            props.className?.description
          )}
        >
          {props.description}
        </Text>
      )}
      {props.children}
    </fieldset>
  )
}
