import { css, cx } from 'emotion'
import * as React from 'react'
import { useThemeContext } from '../contexts'

export interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: boolean
  fixedHeight?: boolean
}

export function TextArea(props: TextAreaProps) {
  const { className, error, fixedHeight, rows = 6, ...baseProps } = props
  const ref = React.useRef<HTMLTextAreaElement>(null)
  const theme = useThemeContext()

  const onInput = () => {
    ref.current!.style.height = 'auto'
    ref.current!.style.height = ref.current!.scrollHeight + 'px'
  }

  React.useEffect(() => {
    let currentRef = ref.current
    if (!fixedHeight) {
      // if the text inside textarea requires more then the default rows, increase the size on the element based on the scrollheight
      ref.current!.setAttribute(
        'style',
        'height:' + ref.current!.scrollHeight + 'px; overflow-y: hidden;'
      )
      // add listener to extend the size of textarea of new input is entered in the field
      ref.current!.addEventListener('input', onInput, false)
    }
    return () => currentRef!.removeEventListener('input', onInput, false)
  }, [fixedHeight, ref])

  function getBorderColor(action?: 'focus') {
    if (error) return theme.palette.error.regular
    if (action === 'focus') return theme.palette.secondary.regular
    if (props.value && props.value.toString().length > 0)
      return theme.palette.success.regular
    return theme.palette.grey[500]
  }

  const textAreaStyle = css`
    width: 100%;
    background-color: ${theme.palette.grey[100]};
    border: none;
    box-shadow: 0px -2px 0px ${getBorderColor()} inset;
    cursor: initial;
    font-size: ${theme.typography.p.fontSize};
    line-height: ${theme.typography.p.lineHeight};
    padding: ${theme.spacing.sm}rem;
    outline: none;
    resize: ${fixedHeight ? 'none' : 'vertical'};
    transition: all ${theme.transitions.duration.fast}s
      ${theme.transitions.easing.easeInOut};
    &:disabled {
      background-color: ${theme.palette.action.disabled.background};
      cursor: not-allowed;
    }
    &:focus {
      box-shadow: 0px -2px 0px ${getBorderColor('focus')} inset;
    }
    &:hover:not(:focus) {
      background-color: ${theme.palette.grey[200]};
    }
    &:hover:disabled {
      background-color: ${theme.palette.action.disabled.background};
    }
    ::placeholder,
    ::-ms-input-placeholder {
      color: ${theme.palette.primary.light};
    }
  `
  return (
    <textarea
      {...baseProps}
      className={cx(textAreaStyle, className)}
      ref={ref}
      rows={rows}
    />
  )
}
