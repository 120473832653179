import { PrimaryButton, Text, useThemeContext } from '@ikea-components'
import { css } from 'emotion'
import React from 'react'
import { Link } from 'react-router-dom'
import logo from 'src/apps/main/assets/ikea-new-logo-dnp.svg'
import { TextField } from 'src/apps/main/components/Form/TextField'
import { VerticalSpacing } from 'src/apps/main/components/layout/VerticalSpacing'
import 'whatwg-fetch'
import { resetDocumentTitle } from '../../helpers/documentTitles'

export function ForgotPage() {
  const theme = useThemeContext()
  const [email, setEmail] = React.useState('')
  const [successfulReset, setSuccessfulReset] = React.useState(false)
  document.title = resetDocumentTitle

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()

    window
      .fetch(`/api/user/password/reset`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user: email
        })
      })
      .then(res => {
        if (res) {
          setSuccessfulReset(true)
        }
      })
  }
  return (
    <div style={{ padding: `${theme.spacing.sm * 2.4}rem` }}>
      <div style={{ height: '10vh' }} />
      <div
        style={{
          maxWidth: `${theme.spacing.sm * 45}rem`,
          margin: '0 auto',
          padding: `${theme.spacing.sm * 2.4}rem`,
          backgroundColor: theme.palette.common.white
        }}
      >
        <VerticalSpacing amount={2} />
        {/* lineHeight comes from old image component, dunno why it was used? */}
        <div style={{ textAlign: 'center', lineHeight: 0 }}>
          <img
            alt="Ikea logo"
            src={logo}
            style={{
              width: '100px'
            }}
          />
        </div>
        <VerticalSpacing amount={8} />
        <Text as="p">
          Please enter your e-mail-address. You will receive a link to create a
          new password via e-mail
        </Text>
        <VerticalSpacing amount={2} />
        <form onSubmit={handleSubmit}>
          <TextField
            inputType="email"
            autoFocus
            value={email}
            onChange={(value: string) => setEmail(value)}
            label="E-mail address"
          />
          <VerticalSpacing amount={2} />
          {successfulReset && (
            <Text
              as="p"
              className={css`
                color: ${theme.palette.success.regular};
              `}
            >
              A message with further details was sent to you, if the provided
              email address exist in the system
            </Text>
          )}
          <VerticalSpacing amount={4} />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Link to="/">
              <Text as="p">{'« Back'}</Text>
            </Link>

            <PrimaryButton type="submit">Reset</PrimaryButton>
          </div>
        </form>
      </div>
    </div>
  )
}
