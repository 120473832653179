import * as React from 'react'

interface Props {
  children: (isHovering: boolean) => React.ReactNode
}

interface HoverState {
  isHovering: boolean
}

export function Hover(props: Props) {
  const [isHovering, setIsHovering] = React.useState(false)

  return (
    <div
      onMouseEnter={() => {
        setIsHovering(true)
      }}
      onMouseLeave={() => {
        setIsHovering(false)
      }}
      style={{ display: 'inline-block' }}
    >
      {props.children(isHovering)}
    </div>
  )
}
