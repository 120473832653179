export type IconTypes =
  | 'msProjectOnline'
  | 'priceTag'
  | 'svgIkeaPoang'
  | 'megaPhone'
  | 'svgTree'
  | 'svgScissors'
  | 'svgQR'
  | 'file'
  | 'word'
  | 'excel'
  | 'pdf'
  | 'powerPoint'
  | 'dpd-cube'

export const pathForIconType = (iconType: IconTypes) => {
  switch (iconType) {
    case 'dpd-cube':
      return 'M12.9 21.6l9.2-5V7.4l-9.2 3.4v10.8zM12 9.2l10.1-3.7L12 1.8 1.9 5.5 12 9.2zm12-3.7v11.1c0 .7-.4 1.3-1 1.6l-10.2 5.5c-.2.2-.5.3-.8.3-.3 0-.6-.1-.9-.2L1 18.2c-.6-.3-1-.9-1-1.6V5.5c0-.4.1-.7.3-1.1.2-.3.5-.5.9-.7L11.4 0h.6c.2 0 .4 0 .6.1l10.2 3.7c.4.1.7.4.9.7.2.3.3.7.3 1z'
    case 'svgTree':
      return 'M10 22v.1c0 1.1.8 1.9 1.8 1.9h.5c1 0 1.8-.8 1.8-1.9V22H10zm8.7-1.8l-1.9-3.3H7.2l-1.9 3.3c-.1.2-.1.4 0 .5.1.2.3.3.4.3h12.5c.2 0 .4-.1.4-.3.2-.2.2-.4.1-.5zM7.6 8.7h8.8c.3 0 .5-.2.5-.5 0-.1-.1-.3-.2-.4L12.3.2c-.2-.3-.7-.3-.9 0L7.1 7.9c-.1.2-.1.4 0 .5.1.2.3.3.5.3zM18 15l-3-5.3H9l-3 5.4c-.1.2-.1.4 0 .5.1.2.3.3.4.3h11.2c.3 0 .5-.2.5-.5 0-.2 0-.4-.1-.4z'
    case 'msProjectOnline':
      return 'M6.8 21.9L.1 20.7V2.8l6.7-1.2C10.5 1 13.5.4 13.6.4c.2 0 .2.1.2 1.2v1.2h4.8c3.3 0 4.9 0 5 .1.1.1.3.2.4.4.2.3.2 16.5 0 16.9-.1.1-.2.3-.4.4-.2.1-1.7.1-5 .1h-4.8V22c0 1 0 1.3-.1 1.3-.2-.2-3.2-.7-6.9-1.4zm16.4-10.2V3.6h-9.6v2h3.1c2.3 0 3.2 0 3.2.1.1.1.1.8.1 2.4v2.3h.9c.5 0 .9 0 .9.1s-.6.7-1.2 1.3l-1.2 1.3-1.3-1.3-1.3-1.3s.4-.1.9-.1h.9V6.8h-5.2v7l1-1 1-1 1.5 1.5 1.5 1.5-1.5 1.5c-.8.8-1.5 1.5-1.6 1.5-.1 0-.6-.4-1.1-1l-1-1v4h9.6l.4-8.1zM5.9 14.3V13h.7c.9 0 1.4-.2 1.9-.8.6-.7.8-1.6.6-2.8-.1-.4-.2-.6-.5-1-.6-.5-1-.6-2.7-.5H4.6v3.8c0 2.1 0 3.8.1 3.8 0 0 .3.1.7.1H6c-.1 0-.1-1.3-.1-1.3zm.1-3.9V9.2h.5c.4 0 .6.1.8.2.3.3.4.8.3 1.4-.1.5-.4.7-1.1.7h-.6l.1-1.1z'
    case 'priceTag':
      return 'M21 4.2v2.2c.9 1.2 1.4 3.2 1.4 5.3 0 3.7-1.6 6.7-3.6 6.7-1 0-1.9-.8-2.6-2.1l-1.1 1.1c.9 1.5 2.2 2.5 3.7 2.5 2.9 0 5.1-3.6 5.1-8.3.1-3.3-1.1-6.1-2.9-7.4zm-3.1-1.9c.8 0 1.5.7 1.5 1.5v7.1L18.3 12l-9.2 9.2c-.3.3-.7.4-1.1.4s-.7 0-1-.3L.5 14.8c-.6-.6-.6-1.6 0-2.2l9.2-9.2 1.1-1.1h7.1m-3 6.1c.4 0 .8-.2 1.1-.5.6-.6.6-1.6 0-2.2-.3-.3-.7-.5-1.1-.5s-.8.2-1.1.5c-.6.6-.6 1.6 0 2.2.3.3.7.5 1.1.5'
    case 'svgIkeaPoang':
      return 'M3.5 22.1c-.3-.1-.4-.4-.5-.7v-.7c0-.6-.1-1.2-.1-1.8 0-.6 0-1.2-.1-1.8v-1.9-.2-.4-.3c0-.4.1-.9.2-1.3.1-.2.1-.4.2-.5.1-.3.4-.5.7-.6l3.9-2.1c.2-.1.4-.3.5-.5.1-.3.2-.6.3-.8l.3-.9c.1-.1.1-.1.2-.1H9.4c.1-.3.2-.6.2-.9l.3-1.8c.1-.3.1-.7.2-1.1 0-.1.1-.2.1-.2H10c-.1 0-.2-.1-.2-.2-.1-.1-.2-.3-.2-.5-.1-.5-.2-1-.2-1.5 0-.3 0-.6.1-.9.1-.2.2-.4.5-.4s.6 0 .9-.1h1.6c.6 0 1.1 0 1.7.1.4 0 .8 0 1.2.1l1.8.1h1.1c.5 0 1 .1 1.5.1h.3s.1 0 .1.1c.1.2.1.4.1.6v.1s0 .1.1.1h.1c.1 0 .1 0 .1.1V4c0 .5 0 1-.1 1.5l-.3 2.1c0 .2-.1.4-.1.5v.1h.3c.3 0 .5 0 .8.1.1 0 .1 0 .1.1v.2c0 .2-.1.3-.1.5v.1c0 .1 0 .1-.1.2v.1l-.3.9c-.1.2-.2.4-.4.6-.1.1-.3.2-.4.3l-.9.6-.1.1c-.1.6-.2 1.2-.2 1.8v.6c0 .4 0 .9-.2 1.3-.1.2-.1.4-.2.5l-.2.2c-.1.1-.1.1-.2.1l-1.8.1h-.1v3.3s0 .1.1.1c.5.1.9.1 1.4.2.5.1.9.1 1.4.2.3 0 .7.1 1 .1h.2c.3 0 .7.1 1 .1.1 0 .1 0 .1.1.1.2 0 .3-.1.4-1.5.9-2.9 1.7-4.4 2.6-.1.1-.2.1-.3.1-.1 0-.2 0-.4.1-.1.1-.2.1-.2.1h-.5c-.1 0-.1 0-.1-.1s0-.1.1-.2c-.3-.1-.4-.3-.4-.6-.1-.3-.1-.5-.1-.8 0-.6-.1-1.1-.1-1.7 0 0 0-.1-.1-.1-.6-.1-1.2-.1-1.8-.2-.5-.1-1.1-.1-1.6-.2s-1-.1-1.5-.2h-.1L5.2 22c-.1 0-.2.1-.2.1-.1.1-.2.1-.4.1h-.2c-.1.1-.2.1-.4.2h-.5c-.1 0-.1 0-.1-.1s.1-.2.1-.2zm.8-.8c.1 0 .1 0 0 0L7 20.1c.6-.3 1.3-.6 1.9-.9H9c.3 0 .6.1.9.1h.2c.3 0 .7.1 1 .1.5.1 1.1.1 1.6.2s1 .1 1.6.2h.3v-.9c-.2 0-.3 0-.5-.1-.5-.1-1.1-.1-1.6-.2-.6-.1-1.1-.1-1.7-.2-.5-.1-1.1-.1-1.6-.2s-1-.1-1.6-.2c-.5-.1-1-.1-1.6-.2-.5-.1-1.1-.1-1.6-.2H4c.2 1.4.2 2.5.3 3.7zm4.1-10.7s-.1 0 0 0c-1.2.7-2.3 1.3-3.5 1.9-.2.1-.3.2-.4.4-.2.3-.3.5-.3.8 0 .1 0 .3-.1.4H8c0-1.1 0-2.4.4-3.5zm4.5 6h-.2c-.6 0-1.2.1-1.9.1s-1.4 0-2.1-.1c-.6-.1-1.1-.1-1.7-.2-.8-.1-1.6-.3-2.4-.6h-.1c-.2 0-.3.1-.5.1 0 0-.1 0-.1.1v.5c0 .1 0 .1.1.1.3 0 .7.1 1 .1.4 0 .8.1 1.2.1.3 0 .6.1.8.1.3 0 .7.1 1 .1.5 0 .7 0 1 .1.3 0 .6.1 1 .1l1 .1c.3 0 .6.1.9.1.2 0 .5.1.7.1.3 0 .6.1.9.1.3 0 .6.1.9.1h.3v-.9c-.2 0-.5.1-.7.2h-.1c-.3 0-.5-.1-.8-.1-.1 0-.1 0-.1-.1-.1 0-.1-.1-.1-.2zm3.3 6.1c1-.6 1.9-1.1 2.9-1.6-1-.1-2-.2-3-.4 0 .7 0 1.3.1 2z'
    case 'megaPhone':
      return 'M2.5 15.1c-.2.2-.2.6 0 .9l3.6 3.6c.2.2.6.2.9 0l.8-.8-4.5-4.4-.8.7zM18 4c-.6-.6-1.5-.7-2.2-.2l2.4 2.4c.5-.7.4-1.6-.2-2.2zm3.6 6.8L11.3.4c-.1-.1-.4-.1-.5.1-.5 2.5-.5 6.6-4.1 10.3l4.4 4.4c3.6-3.4 7.2-3.5 10.3-4.1.3.1.4-.2.2-.3zm-6.7 9.3l-.1-.1.6-.6c.1-.1.1-.4 0-.5-.1-.1-.4-.1-.5 0l-.6.5-3-3-1.8 1.8 5.4 5.4c.1.1.1.1.2 0l2.3-2.3v-.1c-2.5-1.1-2.4-1-2.5-1.1zm-8.7-8.7l-2.3 2.4 4.4 4.4 2.3-2.3-4.4-4.5zm1 3.4c-.2.2-.4.2-.6 0l-1-1c-.1-.1-.1-.4 0-.5.1-.1.4-.1.5 0l1 1c.2.1.2.4.1.5z'
    case 'svgScissors':
      return 'M23.4 17.4L14.3 14c-.3-.1-.7-.4-.9-.7l-2.8-3.9c.5-.5.8-1.2 1-1.9.4-2.1-1-4.1-3.1-4.5S4.4 4 4 6.1s1 4.1 3.1 4.5c.4.1.8.1 1.2 0l3 5.9c.3.5.8.9 1.4 1l9.9 1.9c.6.1 1.2-.3 1.4-.9v-.1c.1-.4-.2-.8-.6-1zM5.3 6.3c.3-1.4 1.6-2.3 3-2.1s2.3 1.6 2.1 3-1.6 2.3-3 2.1c-1.5-.2-2.4-1.6-2.1-3zm16.9 1.4l-.1-.1c-.2-.2-.6-.3-.9-.2-.1 0-.2.1-.3.2l-6.5 5.1c.1.1.2.2.3.2l3.2 1.2 4.3-4.7c.5-.5.4-1.2 0-1.7zm-12 9.3l-1-2-1.8.5c-.3-.6-.8-1.2-1.4-1.6-.1-.1-.2-.1-.3-.1-1.4-.7-3-.5-4.2.4-.3.3-.7.6-.9 1-.3.5-.5 1-.5 1.5-.2 1.5.5 3 1.8 3.8 1.8 1.1 4.2.6 5.3-1.3.2-.3.4-.7.5-1.1l3.1-.3c-.2-.2-.4-.5-.6-.8zm-4.1 1.5c-.8 1.3-2.4 1.7-3.6.9-.2-.1-.4-.3-.6-.5-.5-.6-.7-1.5-.5-2.3.1-.2.2-.5.3-.7.7-1.2 2.3-1.6 3.5-.9.4.3.8.6 1 1.1.3.7.3 1.6-.1 2.4z'
    case 'svgQR':
      return 'M0 0v7h7V0H0zm5 5H2V2h3v3zM0 17v7h7v-7H0zm5 5H2v-3h3v3zM17 0v7h7V0h-7zm5 5h-3V2h3v3zm0 4v6h-5v2h7V9h-2zm-5 10v5h2v-3h3v3h2v-5h-7zM9 0v2h4v5h2V0H9zm4 9v4H9v6h4v5h2v-7h-4v-2h4v-4h2v2h2V9h-6zM9 21h2v3H9v-3zm-5-8h3v2H4v-2zm5-9v5H0v6h2v-4h9V4H9z'
    case 'word':
      return 'M14.4 7.5c-.4 0-.8-.4-.8-.8V0H3.9c-.5 0-1 .4-1 1v22c0 .5.4 1 1 1h16.2c.5 0 1-.4 1-1V7.5h-6.7zm-7.7 4.2h9.1v1.1H6.7v-1.1zm2.6-5.3v1.1H6.7V6.4h2.6zm-2.6 8h10.7v1.1H6.7v-1.1zm0 2.7h5.9v1.1H6.7v-1.1zM21.1 6.4h-5.9c-.3 0-.5-.2-.5-.5V0l6.4 6.4z'
    case 'excel':
      return 'M14.4 7.5c-.4 0-.8-.4-.8-.8V0H3.9c-.5 0-1 .4-1 1v22c0 .5.4 1 1 1h16.2c.5 0 1-.4 1-1V7.5h-6.7zm-.7 11.2h2.1L13 14.9l2.2-3.1h-2.1L12 13.4l-1.2-1.7H8.7l2.2 3.1-2.7 3.8h4.5l-.7-1.1h-.9l.9-1.2 1.7 2.4zm7.4-12.3h-5.9c-.3 0-.5-.2-.5-.5V0l6.4 6.4z'
    case 'pdf':
      return 'M14.4 7.5c-.4 0-.8-.4-.8-.8V0H3.9c-.5 0-1 .4-1 1v22c0 .5.4 1 1 1h16.2c.5 0 1-.4 1-1V7.5h-6.7zm1.8 9.4c-.1.1-.4.1-.7.1-.6 0-1.5-.1-2.2-.5-1.2.1-2.2.3-2.9.6-.1 0-.1 0-.1.1-.9 1.5-1.6 2.3-2.2 2.3-.1 0-.2 0-.3-.1l-.4-.2v-.1c-.1-.1-.1-.2-.1-.4.1-.4.5-1 1.4-1.5.3-.1.5-.2.8-.4.2-.4.4-.8.7-1.3.4-.7.6-1.5.8-2.1-.3-.9-.4-1.4-.1-2.4.1-.3.3-.6.6-.6h.1c.1 0 .3.1.4.1.5.5.3 1.7 0 2.6v.1c.3.8.7 1.5 1.2 1.9.2.1.4.3.7.4.4 0 .7-.1.9-.1.9 0 1.5.1 1.7.5.1.1.1.3.1.4-.1.2-.2.5-.4.6zm-4.7-2.8c-.1.5-.4 1.1-.7 1.8-.1.3-.3.5-.4.8h.2c.9-.4 1.8-.6 2.4-.7-.1-.1-.2-.1-.3-.2-.4-.5-.9-1.1-1.2-1.7zm4.5 2.2c-.1-.1-.4-.3-1.4-.3h-.1v.1c.5.2 1 .4 1.4.4h.1v-.2c0 .1 0 .1 0 0zm-6.7 1.1c-.1.1-.3.1-.4.2-.5.4-.9 1-.9 1.2.4-.1.8-.5 1.3-1.4zm2.2-5c.1-.3.1-.4.1-.7v-.1c.1-.4.1-.7 0-.7v-.1l-.1-.1s0 .1-.1.1c-.1.4-.1.9.1 1.6zm9.6-6h-5.9c-.3 0-.5-.2-.5-.5V0l6.4 6.4z'
    case 'powerPoint':
      return 'M14.4 7.5c-.4 0-.8-.4-.8-.8V0H3.9c-.5 0-1 .4-1 1v22c0 .5.4 1 1 1h16.2c.5 0 1-.4 1-1V7.5h-6.7zm4.1 11.6c-.5.7-1.3 1.2-2.2 1.2-1.5 0-2.7-1.2-2.7-2.7 0-1.5 1.2-2.7 2.7-2.7v2.7l2.2 1.5zM6.1 13.3h2.7v4.3H6.1v-4.3zm3.8-2.6h2.7v6.9H9.9v-6.9zm11.2-4.3h-5.9c-.3 0-.5-.2-.5-.5V0l6.4 6.4z'
    case 'file':
      return 'M14.4 7.5c-.4 0-.8-.4-.8-.8V0H3.9c-.5 0-1 .4-1 1v22c0 .5.4 1 1 1h16.2c.5 0 1-.4 1-1V7.5h-6.7zm6.7-1.1h-5.9c-.3 0-.5-.2-.5-.5V0l6.4 6.4z'
    default:
      throw new Error('Icon does not exist')
  }
}
