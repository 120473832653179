import { PrimaryButton, Text, useThemeContext } from '@ikea-components'
import { css } from 'emotion'
import React from 'react'
import { Link } from 'react-router-dom'
import logo from 'src/apps/main/assets/ikea-new-logo-dnp.svg'
import { TextField } from 'src/apps/main/components/Form/TextField'
import { VerticalSpacing } from 'src/apps/main/components/layout/VerticalSpacing'
import { LinkText } from 'src/apps/main/components/Links/LinkText'
import { spacing } from 'src/apps/main/helpers/CssProperties'
import 'whatwg-fetch'
import { loginDocumentTitle } from '../../helpers/documentTitles'

interface LoginPageState {
  username: string
  password: string
}

const singleSignOnUrl = '/api/user/sso-login'

export function LoginPage() {
  document.title = loginDocumentTitle
  const theme = useThemeContext()
  const [formData, setFormData] = React.useState<LoginPageState>({
    username: '',
    password: ''
  })
  const [formError, setFormError] = React.useState(false)
  const [externalUser, setExternalUser] = React.useState(false) // Toggle between single sign on or external (username/password) login
  function handleSubmit(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault()
    const user = formData.username
    const password = formData.password

    window
      .fetch(`/api/user/login${window.location.search}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user,
          password
        })
      })
      .then(res => {
        if (res.status === 401) {
          setFormError(true)
          return Promise.reject('not authorized')
        }
        return res.json()
      })
      .then(res => {
        if (/Edge/.test(navigator.userAgent)) {
          // Fix for Edge not creating cookies properly from response headers
          var expires = new Date()
          expires.setTime(expires.getTime() + 365 * 24 * 60 * 60 * 1000)
          document.cookie = `auth_token=${
            res.token
          };path=/;expires=${expires.toUTCString()}`
        }

        if (res.redirected) {
          setFormError(false)
          window.location.href = res.url
        }
      })
      .catch(err => console.error(err))
  }

  return (
    <div style={{ padding: `${theme.spacing.sm * 2.4}rem` }}>
      <div style={{ height: '10vh' }} />
      <div
        style={{
          maxWidth: `${theme.spacing.sm * 45}rem`,
          margin: '0 auto',
          padding: `${theme.spacing.sm * 2.4}rem`,
          backgroundColor: theme.palette.common.white
        }}
      >
        {/* lineHeight comes from old image component, dunno why it was used? */}
        <div style={{ textAlign: 'center', lineHeight: 0 }}>
          <img
            alt="Ikea logo"
            src={logo}
            style={{
              width: '100px'
            }}
          />
        </div>
        {!externalUser && (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <Text as="h3">Welcome to Co-create Ideas</Text>

              <PrimaryButton
                onClick={() => {
                  window.location.href = `${singleSignOnUrl}${window.location.search}`
                }}
              >
                Log in
              </PrimaryButton>
            </div>

            <div
              style={{
                paddingTop: `${spacing * 2}rem`,
                textAlign: 'end'
              }}
              role="button"
              onClick={() => setExternalUser(true)}
            >
              <LinkText>External user</LinkText>
            </div>
          </>
        )}
        {externalUser && (
          <form onSubmit={handleSubmit}>
            <TextField
              key={'email'}
              inputType="email"
              autoFocus
              value={formData.username}
              onChange={username => setFormData({ ...formData, username })}
              label="E-mail address"
            />
            <VerticalSpacing amount={2} />
            <TextField
              key={'password'}
              inputType="password"
              value={formData.password}
              onChange={password => setFormData({ ...formData, password })}
              label="Password"
            />
            {formError && (
              <Text
                as="p"
                className={css`
                  color: ${theme.palette.error.regular};
                `}
              >
                Username and/or password is incorrect
              </Text>
            )}
            <VerticalSpacing amount={4} />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Link to="/forgot">
                <Text as="p">Reset password</Text>
              </Link>

              <PrimaryButton type="submit">Log in</PrimaryButton>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}
