import { css, cx } from 'emotion'
import * as React from 'react'
import { useThemeContext } from '../contexts'
import { BaseButton, BaseButtonProps } from './BaseButton'

interface Props extends Omit<BaseButtonProps, 'onChange'> {
  height?: string
  width?: string
}

/** Adding custom props that are not conflicting with HTMLButtonAttributes s*/
interface CustomProps {
  accept?: string
  multiple?: boolean
  selectedFileName?: string
  onChange: (files: FileList) => void
}

interface AddNewFilesCardButtonProps extends Props, CustomProps {}

export function AddNewFilesCardButton(props: AddNewFilesCardButtonProps) {
  const theme = useThemeContext()

  const inputRef = React.useRef<HTMLInputElement>(null)

  const cardButtonStyle = css`
    background-color: ${theme.palette.primary.regular};
    color: ${theme.palette.common.white};
    flex-direction: column;
    > svg {
      width: ${theme.spacing.sm * 5}rem;
      height: ${theme.spacing.sm * 5}rem;
      fill: ${theme.palette.secondary.regular};
    }
  `

  return (
    <>
      <BaseButton
        // props on BaseCardButton can only be of interface Props
        {...(props as Props)}
        mdIcon="MdAdd"
        className={cx([
          cardButtonStyle,
          css`
            width: ${props.height || '100%'};
            height: ${props.height};
          `,
          props.className
        ])}
        onClick={() => {
          inputRef.current?.click()
        }}
      >
        {props.children}
      </BaseButton>
      <input
        ref={inputRef}
        style={{ display: 'none ' }}
        type="file"
        accept={props.accept}
        multiple={props.multiple}
        onChange={e => {
          // esc triggers an event (only when input has been used once) with an empty list. this causes bugs with empty updates
          if (e.target.files && e.target.files!.length > 0) {
            props.onChange(e.target.files!)
          }
        }}
      />
    </>
  )
}
