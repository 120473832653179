import { Text, useThemeContext } from '@ikea-components'
import { css } from 'emotion'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import MdIcons from 'react-icons/md'
import {
  remToPixels,
  screenSize,
  zIndex
} from 'src/apps/main/helpers/CssProperties'
import { VerticalSpacing } from '../layout/VerticalSpacing'
import { Backdrop } from './Backdrop'

export interface ModalProps {
  open: boolean
  title?: string
  closeOnBackdropClick: boolean
  closeWithEscape: boolean
  roundCloseButton?: boolean
  width?: number
  height?: string
  maxWidth?: string
  fullscreen?: boolean
  onCloseRequest: () => void
  onSubmit?: (e: Event) => void
  children: React.ReactNode
  backgroundColor?: string
  borderLeftColor?: string
}

export function Modal(props: ModalProps) {
  const theme = useThemeContext()
  React.useEffect(() => {
    document.addEventListener('keydown', closeOnEscape)
    document.addEventListener('keydown', submitOnEnter)
    return () => {
      document.removeEventListener('keydown', closeOnEscape)
      document.removeEventListener('keydown', submitOnEnter)
    }
  })
  React.useEffect(() => {
    if (props.open) {
      document.body.classList.add('no-scroll')
    }
    if (!props.open) {
      document.body.classList.remove('no-scroll')
    }
    return () => {
      document.body.classList.remove('no-scroll')
    }
  })

  function closeOnEscape(e: KeyboardEvent) {
    if (!props.open) {
      return
    }
    if (e.keyCode === 27 && props.closeWithEscape) {
      props.onCloseRequest()
    }
  }

  function submitOnEnter(e: KeyboardEvent) {
    if (!props.open) {
      return
    }
    if (e.keyCode === 13 && props.onSubmit) {
      e.preventDefault()
      props.onSubmit(e)
    }
  }

  if (!props.open) {
    return null
  }

  return ReactDOM.createPortal(
    <Backdrop
      open={props.open}
      onBackdropClick={() => {
        if (props.closeOnBackdropClick && props.onCloseRequest) {
          props.onCloseRequest()
        }
      }}
    >
      {props.fullscreen ? (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: '100%',
            height: '100%',
            zIndex: zIndex.modal,
            overflow: 'auto',
            background: theme.palette.common.white
          }}
        >
          <div>{props.children}</div>
        </div>
      ) : (
        <div
          className={css({
            position: 'relative',
            width: props.width || '90%',
            height: props.height ? props.height : 'auto',
            padding: `${theme.spacing.sm * 3}rem`,
            margin: `${theme.spacing.md}rem`,
            zIndex: zIndex.modal,
            background: props.backgroundColor
              ? props.backgroundColor
              : theme.palette.common.white,
            borderLeft: props.borderLeftColor
              ? `${theme.shape.border.sm} solid ${props.borderLeftColor}`
              : 'none',
            boxShadow: `0 ${theme.spacing.xs}rem ${theme.spacing.sm}rem 0 rgba(27, 26, 26, 0.2)`,
            border: `${theme.shape.border.sm} solid ${theme.palette.primary.regular}`,
            [`@media only screen and (min-width : ${screenSize.small})`]: {
              maxWidth: props.maxWidth
                ? props.maxWidth
                : `${theme.spacing.sm * 55}rem`,
              width: `calc(100% - ${theme.spacing.sm * 10}rem)`
            }
          })}
        >
          {props.roundCloseButton && (
            <div
              className={css({
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',

                top: `-${theme.spacing.md}rem`,
                right: `-${theme.spacing.md}rem`,
                height: `${theme.spacing.lg}rem`,
                width: `${theme.spacing.lg}rem`,
                zIndex: zIndex.modal,
                border: `${theme.spacing.xs * 0.5}rem solid ${
                  theme.palette.secondary.regular
                }`,
                backgroundColor: theme.palette.primary.regular,
                borderRadius: '50%',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: theme.palette.primary.light
                }
              })}
              onClick={props.onCloseRequest}
            >
              <div
                style={{
                  height: theme.spacing.md * remToPixels,
                  width: theme.spacing.md * remToPixels
                }}
              >
                <MdIcons.MdClear
                  size={theme.typography.icon.md}
                  color={theme.palette.common.white}
                />
              </div>
            </div>
          )}
          <>
            {props.title && (
              <>
                <Text as="h2">{props.title}</Text>
                <VerticalSpacing amount={2} />
              </>
            )}

            {props.children}
          </>
        </div>
      )}
    </Backdrop>,
    document.body
  )
}
