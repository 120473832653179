/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Text, useThemeContext } from '@ikea-components'
import { css } from 'emotion'
import * as React from 'react'

interface Props {
  header: string
  timeInSeconds: number
}

export function SaveNotification(props: Props) {
  const theme = useThemeContext()

  const backgroundColor = theme.palette.success.regular

  return (
    <div
      className={css`
        padding: ${theme.spacing.sm}rem ${theme.spacing.md}rem;
        margin: ${theme.spacing.md}rem;
        position: relative;
        background-color: ${backgroundColor};
      `}
    >
      <Text
        className={css`
          color: white; ;
        `}
      >
        {props.header}
      </Text>
    </div>
  )
}
