import { css, cx } from 'emotion'
import * as React from 'react'

interface Props {
  gap?: string
  className?: string
  children: React.ReactNode
}
/**
 * Layouts children in a toolbar horizontally
 *
 * gap = applied to margin-bottom and margin-right to keep right margin if elements wraps
 *
 */
export function ToolbarWrapper(props: Props) {
  return (
    <div
      className={cx(
        [
          css`
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            > * {
              margin-bottom: ${props.gap};
              margin-right: ${props.gap};
            }
          `
        ],
        props.className
      )}
    >
      {props.children}
    </div>
  )
}
