import * as React from 'react'
import { spacing, screenSize } from '../../helpers/CssProperties'
import { css } from 'emotion'

interface PageWrapperProps {
  children: React.ReactNode
  maxWidth?: number
  leftAlign?: boolean
  backgroundColor?: string
}

export function PageWrapper(props: PageWrapperProps) {
  return (
    <div
      className={css({
        padding: `${spacing * 2}rem`,
        maxWidth: props.maxWidth ? `${props.maxWidth}px` : 'none',
        margin: props.leftAlign ? '0' : 'auto',
        backgroundColor: props.backgroundColor,
        [`@media only screen and (max-width : ${screenSize.small})`]: {
          padding: `${spacing}rem`
        }
      })}
    >
      {props.children}
    </div>
  )
}

PageWrapper.defaultProps = {
  backgroundColor: 'transparent'
}
