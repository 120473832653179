import { css, cx } from 'emotion'
import * as React from 'react'
import { spacing } from '../../../apps/main/helpers/CssProperties'
import { useThemeContext } from '../contexts'
import { BaseButton, BaseButtonProps } from './BaseButton'

interface Props extends BaseButtonProps {}

export function SecondaryButton(props: Props) {
  const theme = useThemeContext()

  const secondaryButtonStyle = css`
    background-color: ${theme.palette.secondary.regular};
    color: ${theme.palette.common.black};
    &:active:enabled,
    &:focus:enabled {
      box-shadow: 0 0 ${spacing}rem ${theme.palette.secondary.regular};
    }
  `

  return (
    <BaseButton
      {...props}
      className={cx(secondaryButtonStyle, props.className)}
    >
      {props.children}
    </BaseButton>
  )
}


